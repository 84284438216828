@import "@/styles/scss/_variables.scss";











.partners {
  &__content {
    margin-top: 20px;
    padding: 0 15px;
    @media (min-width: $desktop-small) {
      max-width: $desktop-layout-width;
      margin: 20px auto 0;
      background-color: $cl-white;
    }
    @media (min-width: $desktop) {
      padding: 0;
    }
    @media (min-width: $desktop-big) {
      max-width: $desktop-big-layout-width;
    }
  }
}
