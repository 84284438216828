@import "@/styles/scss/_variables.scss";






























































































.worktownStep {
  &__field {
    max-width: 345px;
    margin-top: 20px;

    @media screen and (min-width: $tablet) {
      margin-top: 36px;
    }
  }
}

.field__label {
  margin-bottom: 10px;
}
