@import "@/styles/scss/_variables.scss";



































.directApplyThankYou {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background-color: $cl-mako;

  &__info {
    text-align: center;
  }

  &__headline {
    color: $cl-white;
    &.aj-text {
      font-size: 20px;
      line-height: 30px;

      @media (min-width: $tablet) {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  &__button {
    margin-top: 15px;
  }
}
