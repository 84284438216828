.tabs {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .tabs {
    margin-top: 25px;
  }
}
.tabs .tab {
  cursor: pointer;
  flex: 1 0 50%;
  font-size: 14px;
  text-align: center;
  color: #9c9c9c;
}
.tabs .tab.active {
  color: #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}
.tabs .tab:hover {
  color: #4a4a4a;
}
