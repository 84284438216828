




























.sidebar-nav {
  margin: 0;
  padding: 0;
  overflow: hidden;

  @media (min-width: @computerBreakpoint) {
    border-radius: 8px;
    background: @white;
  }

  li {
    list-style: none;
    padding: 8px;
    color: @darkGrey;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: #ff3d4d;
    }

    @media (max-width: @largestTabletScreen) {
      &:first-of-type {
        padding-left: 0;
      }
    }

    @media (min-width: @computerBreakpoint) {
      border-bottom: 1px solid @grey;
      display: flex;
      align-items: center;
      padding: 15px 20px;
      position: relative;
      color: @black;

      &:last-of-type {
        border-bottom: none;
      }
    }

    &.active {
      @media (min-width: @computerBreakpoint) {
        font-weight: bold;
        border-bottom: 1px solid @grey;
        border-left-color: #ff3d4d;
        border-left-style: solid;
        color: #ff3d4d;
        animation: animateBorder 0.5s;
        animation-fill-mode:both;

        @keyframes animateBorder {
          0% { border-left-width: 0; }
          100% { border-left-width: 4px; }
        }
      }

      & > .icon {
        color: #ff3d4d;
        opacity: 1;
      }
    }
  }

  .icon {
    height: auto;
    margin-right: 12px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
