
























































.ui.grid.card {
  width: 100%;
}

h1.header {
  font-weight: normal;
  font-size: 2.2em;
  margin-bottom: 0;
}

.texts {
  height: 100%;
  position: relative;
  @media only screen and (min-width: @tabletBreakpoint) {
    margin-top: 0;
  }

  & > * {
    padding-left: 20px;
  }
}

.texts-header {
  & > .subheader {
    opacity: 0.4;
    font-size: 0.9em;
  }

  margin-top: 40px;
  position: relative;
  @media only screen and (max-width: @largestMobileScreen) {
    margin-top: 1em;
  }

  @media (max-width: @largestSmallMonitor){
    .ui.header {
      font-size: 1.2em;
    }
  }
}

.texts-links {
  font-size: 0.9em;
  line-height: 1.4em;
  opacity: 0.8;

  @media only screen and (min-width: @largestMobileScreen) {
    position: absolute;
  }

  & > .link-wrapper {
    margin-top: 1.2em;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  .link {
    display: block;
    font-weight: bold;
  }
}

.card.background.picture {
  background-color: @primaryColor;
  background-repeat: no-repeat;
  background-size: cover;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
