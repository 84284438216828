/*******************************
    User Variable Overrides
*******************************/
@import "../../../user-defined/category-definitions";

.ui.card {
  .mobile.centered {
    @media only screen and (max-width: @largestMobileScreen) {
      margin: auto;
      text-align: center;
    }
  }
}

.light .ui.cards > .card {
  box-shadow: 0 2px 25px 0 rgba(0,0,0,0.30);
}

.ui.cards {
  &.spaced {
    padding-bottom: @relativeMassive;
    padding-top: @relativeMassive;
  }

  .card {
    border-radius: 16px;

    &.fixed.height {
      height: 350px;

      &.new-tile {
        width: calc(100% - 15%);
        height: auto !important;
        margin-bottom: 20px;
      }

      > .content:not(.fixed) {
        display: flex;
        flex-direction: column;
        flex: 1 0 60%;

        .description {
          flex: 1 1 auto;
          // overflow: auto;
        }

        .extra.content {
          flex: 0 0 auto;
          margin-top: .5em;
        }
      }

      &.compact {
        > .content {
          padding: 10px;

          .title {
            margin-bottom: 10px;
            margin-top: 0;
            height: 38px;
            font-size: 19px;
          }
        }
      }

      // My Desk & services
      &.my-desk,
      &.services {
        display: flex !important;
        height: 324px !important;
        font-size: 14px;

        &.category {
          .listCategoryColor();
          background-position: center !important;
        }
      }

      // services
      &.services {
        &.category {
          background-blend-mode: normal !important;
        }
      }

      // City
      &.city {
        height: 324px !important;

        &.background {
          background-color: lighten(@darkBlue, 20%) !important;

          &.picture {
            background-size: cover;
          }
        }

        .label {
          position: absolute;
          top: 25px;
          padding: 0 5%;
          width: 100%;
          color: @white;
          font-weight: lighter;
          opacity: .8;
        }

        .name {
          color: @white;
          font-size: 32px;
          line-height: 40px;
          font-weight: lighter;
          padding: 0 5%;
          width: 100%;
        }

        .item {
          position: absolute;
          bottom: 30px;
          width: 100%;
        }
      }

    }

    > .content {
      &.fixed {
        flex: 0 0 auto;

        .header {
          margin: 0.5em auto;

          &.small {
            font-size: @relativeSmall;
          }
        }
      }

      &.quote {
        .description {
          color: @darkGrey;
          font-size: 0.875rem;
        }
      }

      .light.medium.header {
        color: @darkGrey;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: @relativeMedium;
      }
    }

    &.ad {
      background-color: @primaryColor;

      .description {
        color: @secondaryColor;
        margin-bottom: @relativeHuge;
      }
    }

    &.inverted {
      background-color: @primaryColor;

      &,
      .header {
        color: @background;
      }
    }

    &.transparent {
      background: transparent;
      box-shadow: none;
    }

    &.condensed {
      > .content:not(:last-child) {
        padding-bottom: 0;
      }
      &.more {
        > .content:not(:first-child) {
          padding-top: @relative4px;
        }
      }
    }

    .flex.description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .flex.centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 5%;
    }

    .flex.spaced-around {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      padding: 5%;
    }

    &.extended {
      flex: 1 100%;
      margin: 20px 0;

      .listCategoryColor();
    }
  }

  &.link {
    > .transparent.card:hover {
      background: transparent;
      box-shadow: none;
    }
  }
}

.ui.jobOffers,
.ui.services,
.ui.review {
  &.four.doubling.stackable.cards > .card {
    // Special case to use three instead of four cards (1400px)
    @media only screen and (min-width: @computerBreakpoint) and (max-width: 1399px) {
      width: ~"calc(33.3% - 10px)";
    }
  }
}
