@import "@/styles/scss/_variables.scss";



















.hero {
  position: relative;

  &__shapes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &__shape {
    position: absolute;
    z-index: 0;

    &.-logo {
      left: -99px;
      top: -83px;
      height: 224px;
      fill: $cl-dawn-pink-lighten-2;

      @media (min-width: $mobile-big) {
        left: -68px;
        top: -60px;
        width: 260px;
      }

      @media (min-width: $tablet) {
        top: -75px;
        left: -46px;
      }

      @media (min-width: $desktop-small) {
        top: -120px;
        left: -4%;
        width: 313px;
        height: 273px;
      }
    }

    &.-top {
      display: none;
      left: 62%;
      top: -155px;
      width: 156px;
      height: 301px;
      fill: $cl-supernova-lighten-16;

      @media (min-width: $desktop-small) {
        display: block;
      }
    }
  }
}
