































































h2, h3, h4 {
  font-weight: normal;
}

h3 {
  margin: 20px 0 4px;
  font-size: 1.1rem;
}

h4 {
  font-size: 1.3rem;
}

.quite-narrow {
  max-width: 735px;
  position: relative;
}

.ui.modal.active {
  .onModal {
    padding: 0 !important;

    .quite-narrow {

      h2 {
        margin-top: 40px;
      }

      .noReviews {
        margin-top: 10px;
      }
    }
  }

  .ui.container.text.quite-narrow {
    width: 100% !important;
  }
}

.ui.container.text.quite-narrow {
  display: flex;
  flex-direction: column;

  .add-review {
    margin-bottom: 20px;
  }

  .noReviews {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: @largestMobileScreen) {
    width: 560px;
  }

  @media only screen and (min-width: @largestTabletScreen) {
    width: 767px;
  }
}

.ui.inverted.active.dimmer {
  padding-top: 150px;
}

.noReviews {
  div {
    margin: 5px 0;
  }

  &__button {
    margin: 15px 0;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
