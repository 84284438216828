.slides-image {
  width: 100%;
  height: 127px;
}
.slides-info {
  padding: 25px;
}
.slides-info-title {
  color: #000;
  display: block;
  margin-bottom: 10px;
}
.slides-info-count {
  color: #9c9c9c;
  margin-bottom: 0;
}
.slides-info-header {
  line-height: 1;
  font-size: 18px;
}
