@import "@/styles/scss/_variables.scss";












































.moreJobs__banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  padding: 25px;
  background-color: $cl-mako-lighten-16;
  overflow: hidden;

  @media screen and (min-width: $desktop) {
    margin: 0;
  }
}

.moreJobsBanner {
  &__searchIcon {
    position: absolute;
    width: 45px;
    fill: $cl-supernova;

    @media screen and (min-width: $tablet) {
      position: relative;
      width: 60px;
      margin-bottom: 24px;
    }
  }

  &__content {
    position: relative;
    padding-left: 58px;
    color: $cl-white;
    @media screen and (min-width: $tablet) {
      padding-left: 0;
    }
  }

  &__headline {
    margin-bottom: 8px;
  }

  &__description {
    color: $cl-mako-lighten-64;
    &.aj-text {
      @media screen and (min-width: $tablet) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__link {
    position: relative;
    margin: 20px 0 0 56px;
    color: $cl-sail-darken-12;
    text-align: left;
    font-weight: bold;

    &:hover {
      svg {
        left: 9px;
      }
    }

    @media screen and (min-width: $tablet) {
      margin: auto 0 8px 0;
    }

    svg {
      position: relative;
      top: 2px;
      left: 6px;
      transition: left .2s ease-in-out;
    }

    .aj-text {
      color: $cl-supernova;
    }
  }

  &__shapeBackground {
    position: absolute;
    z-index: 0;
    top: -160px;
    left: -150px;
    width: 430px;
    transform: rotate(220deg);
    fill: $cl-mako;
    @media screen and (min-width: $mobile) {
      top: -360px;
      left: -350px;
      width: 700px;
      transform: rotate(-20deg);
    }
    @media screen and (min-width: $mobile-big) {
      left: -220px;
    }
    @media screen and (min-width: $tablet) {
      top: -190px;
      left: -250px;
      width: 700px;
      transform: rotate(316deg);
    }
    @media screen and (min-width: $desktop-small) {
      left: -20px;
    }
  }
}
.city__moreJobsBanner {
  margin-bottom: 20px;
  @media screen and (min-width: $desktop) {
    max-width: $desktop-layout-width;
    margin: 0 auto 40px;
  }
  @media screen and (min-width: $desktop-big) {
    max-width: $desktop-big-layout-width;
  }
}

.jobOffer__moreJobsBanner {
  margin: 0;
}

.city__moreJobsBanner,
.jobOffer__moreJobsBanner {
  &.moreJobs__banner {
    @media screen and (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
    }
    @media screen and (min-width: $desktop) {
      padding: 40px 32px;
    }
  }
  .moreJobsBanner {
    &__searchIcon {
      @media screen and (min-width: $tablet) {
        margin: 0 30px 0 0;
      }
      @media screen and (min-width: $desktop) {
        width: 94px;
      }
    }

    &__content {
      @media screen and (min-width: $tablet) {
        padding-right: 30px;
      }
    }

    &__description {
      &.aj-text {
        @media screen and (min-width: $desktop) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &__link {
      @media screen and (min-width: $tablet) {
        position: relative;
        flex: 0 0 auto;
        align-self: center;
        margin: 0 0 0 auto;
      }

      .aj-text {
        @media screen and (min-width: $desktop) {
          font-size: 20px;
        }
      }

      svg {
        @media screen and (min-width: $desktop) {
          top: 0;
        }
      }
    }
  }
}
