



































































.center.aligned.content {
  height: 150px;
  position: relative;
}

.promoted-offers {
  background-color: @yellowish;
  padding: 18px;

  &.rounded {
    border-radius: 5px;
  }

  > .ui.header {
    margin-bottom: 0;
    margin-top: 3px;
  }
}

.ui.carousel {
  min-height: 435px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .item {
    display: flex;
    justify-content: center;
  }
}

.carousel-nav {
  &.carousel-nav-prev {
    left: -25px;

    @media only screen and (min-width: @largestMobileScreen) {
      left: -15px;
    }
  }

  &.carousel-nav-next {
    right: -25px;

    @media only screen and (min-width: @largestMobileScreen) {
      right: -15px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
