@import "@/styles/scss/_variables.scss";





















































































































































































































.description__overviewContent h2 {
  margin-bottom: 10px;
  font: 900 21px/1.5em $font-primary;
  @media screen and (min-width: $tablet) {
    font: 900 26px/1.5em $font-primary;
  }
}
