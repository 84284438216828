@import "@/styles/scss/_variables.scss";































$aj-modal__containter-paddingTop: 40px;
$aj-steps__progress-height: 22px;

.app {
  &.-cookieOpened {
    .entry__step {
      padding-bottom: 75px;
    }
  }
}

.entryStep {
  position: relative;
  &.onboarding__step {
    height: calc(100vh - #{$aj-steps__progress-height} - #{$aj-modal__containter-paddingTop});
    max-width: none;
  }
}

.entryFlow {
  .entry__step {
    padding-top: 0;

    &Back {
      margin-bottom: 5px;
      cursor: pointer;

      @media screen and (min-width: $desktop) {
        position: absolute;
        left: 0;
        top: 40px;
      }

      svg {
        height: 24px;
        width: auto;
      }
    }
  }
}

.entry {
  &__stepHeadline {
    color: $cl-mako;
  }
}
