@import "@/styles/scss/_variables.scss";



































































































.root__linearGradient {
  position: absolute;
}
.flashMessage {
  position: fixed;
  z-index: 10000;
  width: 100%;
  bottom: 0;
  @media screen and (min-width: 769px) {
    top: 0;
    bottom: auto;
  }
}
