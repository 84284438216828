





















.slot-handler {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.scroll {
  position: relative;
  display: flex;

  .scroll-nav {
    z-index: 1;
    padding: 0 .5em;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2.2rem;
    position: absolute;

    &.scroll-left {
      left: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0,
      rgba(255, 255, 255, 1) .8em, rgba(255, 255, 255, 0) 100%);
    }

    &.scroll-right {
      right: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 1) 0,
      rgba(255, 255, 255, 1) .8em, rgba(255, 255, 255, 0) 100%);
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
