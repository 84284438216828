


































.uploader {
  &__link {
    margin-left: 10px;
    display: inline-block;
    font-size: @tiny;
  }
  .image-wrapper {
    border-radius: 50%;
    box-shadow: @lightBlack 0 2px 4px;
    display: inline-block;
    margin: 0 auto;

    .image {
      position: relative;
      overflow: hidden;
      width: 100px;
      height: 100px;
      padding: 0;
      margin: auto;
      box-shadow: inset @special 0 0 5px 2px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;

      &.error {
        box-shadow: inset @red 0 0 6px 5px;
      }
    }
  }

  .menu {
    margin-top: 10px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
