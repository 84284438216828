@import "@/styles/scss/_variables.scss";























































































.partnerOffer {
  &__item {
    display: block;
    margin-bottom: 15px;

    .aj-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (min-width: $desktop) {
        flex-wrap: nowrap;
      }
    }
  }

  &__name {
    flex: 0 1 100%;
    margin-bottom: 10px;

    @media (min-width: $desktop) {
      margin-bottom: 0;
    }
  }

  &__details,
  &__apply {
    flex: 0 1 48%;

    @media (min-width: $desktop) {
      flex: 0 0 auto;
    }
  }

  &__details {
    @media (min-width: $desktop) {
      margin-left: auto;
      margin-right: 10px;
    }
  }
}
