@import "@/styles/scss/_variables.scss";


































.reviews {
  padding: 32px 18px 24px;
  background: $cl-dawn-pink-lighten-6;

  @media (min-width: $tablet) {
    padding: 60px 64px 32px;
  }

  &__headline {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: $tablet) {
      text-align: left;
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto;

    @media (max-width: $desktop-small) {
      display: -webkit-box;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
      }

      &::-webkit-scrollbar-track {
        height: 0;
        width: 0;
      }
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
  }

  &__join {
    margin: 30px auto 0;
    display: table;
    width: 250px;

    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }
  }
}
