.ui.carousel {
  position: relative;
  overflow: hidden;
  color: @textColor;

  > .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media only screen and (min-width: @tabletBreakpoint) {
      height: 100%;
    }

  }
}

.carousel-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.carousel-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 130px;
  z-index: 1;
  stroke: #5E6775;

  @media only screen and (min-width: @largestMobileScreen) {
    position: relative;
    top: 0;
  }

  &:not(.inactive):hover {
    .iconic {
      stroke: #3A3F48;
    }
  }

  &.inactive {
    opacity: 0.2;
    cursor: default;
  }

  .iconic {
    width: 2em;
    height: 2em;
    stroke: #5E6775;
    margin: 8px;
  }

  .light {
    stroke: #fff;
  }

  &.carousel-nav-prev {
    left: -15px;
  }

  &.carousel-nav-next {
    right: -15px;
  }
}

.carousel-left-enter-active,
.carousel-left-leave-active,
.carousel-right-enter-active,
.carousel-right-leave-active {
  transition: all 1s ease;
}

.carousel-left-enter,
.carousel-left-leave-to,
.carousel-right-enter,
.carousel-right-leave-to {
  transform-origin: top;
  opacity: 0;
}

.carousel-left-enter,
.carousel-left-leave-to {
  transform: translateX(-100%);
}

.carousel-left-enter {
  transform: translateX(100%);
}

.carousel-right-enter,
.carousel-right-leave-to {
  transform: translateX(100%);
}

.carousel-right-enter {
  transform: translateX(-100%);
}
