













































.ui.modal {
  > .content > * {
    padding: 0 2.5rem !important;
  }

  h1, h2 {
    font-weight: normal;
    padding: 0;
    margin: 0;
  }

  h1 {
    margin-top: 40px;
  }

  h2 {
    font-size: 1.2rem;
    color: @darkGrey;
    margin-bottom: 16px;
  }

  .label, .review {
    color: @darkGrey;
  }

  .label {
    font-size: 1.1rem;
  }

  .review {
    font-style: italic;
    font-size: 0.9rem;
  }

  button {
    margin: 25px 0;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
