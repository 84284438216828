.stackable.cards .card {
  padding: 3em;
}
.stackable.cards .card .image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
}
.stackable.cards .card .image:before {
  content: '';
  padding-top: 100%;
  display: inline-block;
}
.stackable.cards .card .image > .default {
  color: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
}
.stackable.cards .card > .content > .header {
  color: #485e7a;
}
