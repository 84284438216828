.drawer {
  @headerSize: 56px;

  transition: transform 300ms ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: @white;
  transform: translate3d(110%, 0%, 0);

  &.active {
    box-shadow: 0 0 5px 5px @grey;
    transform: translate3d(0%, 0%, 0);
  }

  > .header {
    display: flex;
    min-height: @headerSize;

    > .title {
      flex: 1 1 auto;
      text-align: center;
      align-self: center;
      font-weight: bold;
    }

    > .close {
      color: @blue;
      text-align: center;
      align-self: center;
      width: @headerSize;

      .icon {
        height: 30px;
      }
    }
  }

  > .content {
    height: calc(100% ~"-" @headerSize);
  }
}
