
































































































































































































































































































@fontColor: @lightBlack;

.user-menu {
  .mobile-inital {
    display: flex;
    align-items: center;

    .white {
      margin-left: 20px;
    }
  }

    &.ui.ui.ui.dropdown > .new.menu {
      border: 0;
      z-index: 100;
      max-width: 100%;
      right: -10px;
      top: 40px;

      @media (min-width: @tabletBreakpoint) {

        &::before {
          position: absolute;
          content: "";
          width: 13px;
          height: 13px;
          background: @white;
          transform: rotate(45deg);
          z-index: 2;
          top: -.3em;
          right: 15px;
          bottom: auto;
          left: auto;
          margin-left: 0;
        }
      }

      &.vertical {
        padding-left: 0;
        box-shadow: -2px 0 16px 0 rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        @media screen and (max-width: @largestMobileScreen) {
          margin-top: 0;
          height: 105vh;
          overflow: scroll;
        }
      }
      .upper-wrapper {
        padding: 24px 20px;
        border-bottom: 1px solid rgba(32, 32, 32, 0.1);

        &.darker {
          background-color: #f9fafc;
          width: 300px;
        }

        &.unlogged {
          padding-left: 0;
          padding-right: 0;
        }

        .ui.large.fluid {
          width: 126px;
          height: 40px;
          margin-top: 4px;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;

          &.-primary{
            margin-left: 16px;
            margin-right: 16px;
          }

          &.-subtleLight {
            margin-right: 16px;
          }
        }
        > .profile {
          display: flex;
          flex-direction: column;
          width: 300px;

          .profile-name.row {
            display: flex;
          }

          .ui.header.column {
            margin-top: 0;
          }
        }
      }

      text-align: left;
      width: 300px;
      .mobile.only.closing {
        text-align: right;
        padding-right: 24px;
        color: #495e7a;
        width: 35px;
        height: 16px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        span {
          position: absolute;
          right: 41px;
        }

        .close.icon {
          cursor: pointer;
          margin-right: 0;
          position: absolute;
          right: 17px;
          top: 24px;
          z-index: 15;

          &.black {
            color: @fontColor;
          }
        }
      }

      .unlogged-user {
        display: flex;
        padding-top: 32px;
        justify-content: space-around;
        align-items: baseline;

        @media screen and (min-width: @largestMobileScreen) {
          padding-top: 0;
        }
      }

      .about-profile {
        display: flex;
        justify-content: left;
        align-items: baseline;
        margin: 0;
        .ui.small.header {

          margin: 14px;
        }

        .ui.small.header:first-of-type {
          margin-left: 0;
        }
        .ui.small.header:active {
          color: #f6b026;
        }
        .sub.header {
          color: @fontColor;
        }
      }

      .item.menu-logout {
        height: 64px;
        line-height: 64px;
        color: @fontColor;
        display: inline-flex;
        padding-left: 20px !important;

        .logout-text {
          height: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #222222;
        }

        .logout {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin: 20px 16px 20px 0;
        }
      }
    @media screen and (max-width: @largestMobileScreen) {
      .arrow-right-1 {
        width: 16px;
        height: 16px;
      }

      &.visible {
        position: absolute;
        top: -3px;
        display: flex !important;
        flex-direction: column;
        right: -15px;
      }

        height: 100vh;

        .mobile-menu {
          width: 300px;
          .single-neutral-home {
            width: 30px;
          }
          a.item {
            justify-content: left;
            color: @fontColor;
            margin-left: 0;
            border-bottom: 1px solid @lightGrey;
            padding: 15px 0 13px 16px;

            p.ui.small.header {
              width: 216px;
              height: 19px;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-bottom: 0;
            }
            span.ui.light.sub.header {
              text-transform: inherit;
              width: 216px;
              height: 16px;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #5c779a;
              white-space: initial;
            }
          }
          a.active {
            border-left: 4px solid @cl-primary-pinkish-red;
            padding-left: 12px;
            p.ui.small.header {
              color: @cl-primary-pinkish-red;
            }

            svg {
              fill: @cl-primary-pinkish-red;
            }
          }

          .language.item {

            padding: 20px 16px;
            .ui.dropdown.language-selector {
              color: @fontColor !important;
            }
            .ui.dropdown.language-selector:active {
              color: @fontColor;

              > .menu.transition.visible {
                max-height: 50vh;
              }
            }
          }
        }

        .mobile-menu__icon {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 16px;
          fill: #9AABC2;
        }
      }
      @media screen and (max-width: 375px) {

        .about-profile {
          padding-left: 0.5em;

          .ui.small.header {
            margin: 10px;

            .sub.header {
              font-size: 12px;
            }

          }
        }

        .mobile-menu {
          a.item {
            span.ui.light.sub.header {
              font-size: 12px;
            }
          }
        }
      }
    }
    .pin-1 {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 4px;
    }

  .single-neutral-circle,
  .navigation-menu {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .profile-picture {
    width: 56px;
    height: 56px;
    margin: 0 16px 24px 0;
  }

  .profile-avatar {
    width: 56px;
    height: 56px;
    object-fit: contain;
    fill: @cl-primary-pinkish-red;
  }

  .ui.header.column.full-name {
    text-transform: capitalize;
    height: 20px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232d3a;
    padding-top: 6px;
    white-space: initial;

    .sub.header.city-name {
      display: flex;
      align-items: center;
      padding-top: 8px;
      height: 24px;
      font-size: 14px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
