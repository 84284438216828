@import "@/styles/scss/_variables.scss";









































.toolbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  @media screen and (min-width: $tablet) {
    justify-content: space-between;
    margin-top: 0;
  }

  .toolbar__filters,
  .toolbar__alert {
    flex: 1;

    @media screen and (min-width: $tablet) {
      flex: initial;
    }
  }
  .toolbar__alert {
    position: relative;
    margin-left: 10px;
    @media screen and (min-width: $tablet) {
      margin-left: 15px;
    }

    .newLabel {
      position: absolute;
      top: -15px;
      right: -5px;
      @media screen and (min-width: $desktop) {
        top: -10px;
        right: -25px;
      }
    }
  }

  &__filtersButton,
  &__alertButton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    text-align: left;
    @media screen and (min-width: $tablet) {
      width: unset;
    }

    &.aj-button {
      padding-left: 14px;
      padding-right: 14px;

      @media screen and (min-width: $tablet) {
        padding-left: 1.714rem;
        padding-right: 1.714rem;
      }
    }
  }

  &__alertButton {
    .aj-text {
      font: bold 14px/1.3em $font-primary;
      @media screen and (min-width: $tablet) {
        font: bold 16px/1.4em $font-primary;
      }
    }
  }

  &__buttonIcon {
    width: 21px;
    margin-right: 10px;
  }

  &__sorting {
    order: 3;
    width: 100%;
    margin-top: 15px;
    @media screen and (min-width: $tablet) {
      width: auto;
      margin-top: 0;
      margin-left: auto;
      order: unset;
    }

    .aj-input__icon {
      transform: rotate(90deg);
    }
    &.aj-select--opened  {
      .aj-input__icon {
        transform: rotate(270deg);
      }
    }
  }
}
