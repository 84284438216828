.ui.bubble {

  // Sizes
  @smallSize: 26px;
  @normalSize: 35px;
  @mediumSize: 44px;
  @semiBigSize: 60px;
  @bigSize: 100px;
  @largeSize: 120px;

  .name {
    text-align: center;
  }

  .icon {
    margin: auto;
    position: relative;

    .background,
    .pictogram {
      left: 0;
      position: absolute;
      top: 0;
    }

    .pictogram {
      color: @white;
      left: 25%;
      margin: auto;
      width: 50% !important;
    }

    // Sizes implementation
    &.large.size, &.large-desktop.size {
      padding-left: 4px;
    }

    &.small.size,
    .small.size {
      line-height: @smallSize;
      height: @smallSize;
      width: @smallSize;
    }

    &.normal.size,
    .normal.size {
      line-height: @normalSize;
      height: @normalSize;
      width: @normalSize;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      &.medium.size,
      .medium.size {
        line-height: @mediumSize;
        height: @mediumSize;
        width: @mediumSize;
      }

      &.semibig.size,
      .semibig.size {
        line-height: @semiBigSize;
        height: @semiBigSize;
        width: @semiBigSize;
      }

      &.big.size,
      .big.size {
        line-height: @bigSize;
        height: @bigSize;
        width: @bigSize;
      }

      &.large.size,
      .large.size {
        line-height: @largeSize;
        height: @largeSize;
        width: @largeSize;
      }
    }

    @media only screen and (min-width: @tabletBreakpoint) {
      &.medium-desktop.size,
      .medium-desktop.size {
        line-height: @mediumSize;
        height: @mediumSize;
        width: @mediumSize;
      }

      &.semibig-desktop.size,
      .semibig-desktop.size {
        line-height: @semiBigSize;
        height: @semiBigSize;
        width: @semiBigSize;
      }

      &.big-desktop.size,
      .big-desktop.size {
        line-height: @bigSize;
        height: @bigSize;
        width: @bigSize;
      }

      &.large-desktop.size,
      .large-desktop.size {
        line-height: @largeSize;
        height: @largeSize;
        width: @largeSize;
      }
    }
  }

  &.inline-block {
    display: inline-block;
    vertical-align: middle;
  }

  // Colors
  &.special {
    .background {
      color: @special;
    }
  }
}
