@import "@/styles/scss/_variables.scss";


























.moreJobsView {
  &__search {
    max-width: 950px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-small) {
      flex-direction: row;
    }
  }
  &__searchKeywords,
  &__searchLocation {
    flex: 1 1 0%;
  }
  &__searchKeywords {
    margin-bottom: 15px;
    @media (min-width: $desktop-small) {
      margin-right: 15px;
      margin-bottom: 0;
      max-width: 350px;
    }
  }
}
