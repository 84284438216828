.center.aligned.content {
  height: 150px;
  position: relative;
}
.promoted-offers {
  background-color: #f5f0d5;
  padding: 18px;
}
.promoted-offers.rounded {
  border-radius: 5px;
}
.promoted-offers > .ui.header {
  margin-bottom: 0;
  margin-top: 3px;
}
.ui.carousel {
  min-height: 435px;
  width: 100%;
}
.ui.carousel::-webkit-scrollbar {
  width: 0;
}
.ui.carousel .item {
  display: flex;
  justify-content: center;
}
.carousel-nav.carousel-nav-prev {
  left: -25px;
}
@media only screen and (min-width: 767px) {
  .carousel-nav.carousel-nav-prev {
    left: -15px;
  }
}
.carousel-nav.carousel-nav-next {
  right: -25px;
}
@media only screen and (min-width: 767px) {
  .carousel-nav.carousel-nav-next {
    right: -15px;
  }
}
