.city-selector {
  position: relative;
  width: 100%;
}
.city-selector-request {
  position: absolute;
  top: -75px;
  border-radius: 24px;
  width: 100%;
  background: #fff;
  padding: 10px 15px;
  color: #485e7a;
  text-align: left;
  z-index: 9;
}
.city-selector-request-info {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.city-selector-request-action {
  cursor: pointer;
}
