@import "@/styles/scss/_variables.scss";




























































.cityCategory {
  &__breadcrumbs {
    display: none;

    @media screen and (min-width: $tablet) {
      display: block;
      padding: 20px;
      background: $cl-white;
    }
  }

  &__goBackLink,
  &__breadcrumbs,
  &__toolbar {
    @media screen and (min-width: $desktop) {
      max-width: $desktop-layout-width;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (min-width: $desktop-big) {
      max-width: $desktop-big-layout-width;
    }
  }
}
