//            name             color    icon
//                                      true = use name
//                                      false = use default

@categories:  babysitting-jobs,          #AD7CAB, true,
              cleaning-jobs,             #B6BC5E, true,
              delivery-jobs,             #504684, true,
              driver-opportunities,      #304866, true,
              driver-jobs,               #4769A3, true,
              freelance-jobs,            #0098D8, true,
              flex-jobs,                 #0098D8, true,
              handyman-jobs,             #234556, true,
              healthcare-medical-jobs,   #E45858, true,
              home-chef,                 #829054, true,
              house-sitting,             #AD7CAB, true,
              local-guides,              #29A1A5, true,
              massage-therapist-jobs,    #982B72, true,
              online-surveys,            #0098D8, true,
              pet-sitting-walking-jobs,  #76614F, true,
              private-car-rentals,       #834230, true,
              private-home-rentals,      #D07E5A, true,
              scooter-charging,          #495e7a, true,
              teaching-jobs,             #72A595, true,
              work-from-home,            #0098D8, true,
              virtual-assistant,         #CBE506, true,
              data-entry,                #0EBCCC, true,
              qa-testing,                #DD8808, true,
              transcriptionist,          #0CCCA8, true,
              translation-jobs,          #7BB500, true,
              profile,                   lighten(#1f5499, 15%), true;


@defaultCategoryColor: #777776;
@borderWidth: 2px;

// Mixins

.categoryColor(@color, @slug) {
  color: @color;

  &.background {
    background-color: darken(@color, 15%) !important;
    color: @white;

    &.picture {
      background-blend-mode: multiply;
      background-size: cover;
      color: @white;
    }
  }
}

.categoryFilterColor(@color) {
  background-color: @color;
  color: @white;

  &:hover,
  &:active {
    background-color: lighten(@color, 10%);
    color: @white;
  }
}

.categoryFilterInvertedColor(@color, @bgColor: @white) {
  background-color: @bgColor;
  color: @color;
  box-shadow: 0 0 0 @borderWidth @color inset;

  &:hover,
  &:active {
    background-color: lighten(@bgColor, 10%);
    color: darken(@color, 10%);
    box-shadow: 0 0 0 @borderWidth darken(@color, 10%) inset;
  }
}

.listCategoryColor(@i: length(@categories)) when (@i > 0) {
  @color: extract(@categories, (@i - 1));
  @name: extract(@categories, (@i - 2));

  &.@{name} {
    .categoryColor(@color, @name);
  }
  .listCategoryColor((@i - 3));

};

.listFooter(@i: length(@categories)) when (@i > 0) {
  @color: extract(@categories, (@i - 1));
  @name: extract(@categories, (@i - 2));

  .@{name} + .ui.segment.vertical.footer {
    background-color: darken(@color, 15%);
  }
  .listFooter((@i - 3));

};

.listCategoryFilter(@i: length(@categories)) when (@i > 0) {
  @color: extract(@categories, (@i - 1));
  @name: extract(@categories, (@i - 2));

  &.@{name} {
    .categoryFilterColor(@color);
  }
  .listCategoryFilter((@i - 3));

};

.listCategoryInvertedFilter(@i: length(@categories)) when (@i > 0) {
  @color: extract(@categories, (@i - 1));
  @name: extract(@categories, (@i - 2));

  &.@{name} {
    .categoryFilterInvertedColor(@color);
  }
  .listCategoryInvertedFilter((@i - 3));

};
