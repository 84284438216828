// mostly copied from "~semantic-ui-less/definitions/elements/divider"
.divider {
  display: table;
  height: auto;
  font-size: 14px;
  margin: 6px auto;
  min-height: 1em;
  line-height: 1;
  text-align: center;
}

.divider:before,
.divider:after {
  content: '';
  background-image: linear-gradient(180deg,
  rgba(0,0,0,0) calc(~"50% - 1px"),
  #979797 calc(50%),
  rgba(0,0,0,0) calc(~"50% + 1px")
  );
  display: table-cell;
  position: relative;
  top: 50%;
  width: 20px;
  background-repeat: no-repeat;
}

.divider:before {
  background-position: right 1em top 50%;
}
.divider:after {
  background-position: left 1em top 50%;
}

.empty.divider:before {
  background-position: right 0 top 50%;
}
.empty.divider:after {
  background-position: left 0 top 50%;
}
