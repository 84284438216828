



































.ui.modal {
  padding: 20px 40px;

  @media screen and (min-width: @tabletBreakpoint) {
    max-width: 400px;
    padding: 40px;
  }
}

@media screen and (min-width: @largestMobileScreen) {
  .ui.modal .close {
    right: 0.5em;
    top: 0.5em;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
