@import "@/styles/scss/_variables.scss";










































.redirectPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $cl-mako;

  &__logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 90%;

    > img {
      max-width: 166px;
    }

    .aj-partnerLogo__fallbackLogo {
      width: 50px;
      height: 50px;
      font-size: 30px;
    }
  }

  &__info {
    width: 400px;
    max-width: 90%;
    color: $cl-white;
    margin-top: 20px;
  }

  &__headline {
    margin-bottom: 15px;
    &.aj-text {
      font-size: 20px;
      line-height: 30px;

      @media (min-width: $tablet) {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  .aj-partnerLogo {
    width: 92px;
    height: 92px;
  }
}
