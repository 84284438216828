.auth-button {
  display: flex;
  align-items: center;
  text-align: center;
}
.auth-button.hidden {
  display: none;
}
@media only screen and (min-width: 767px) {
  .auth-button .user-menu {
    transition: all 800ms ease;
  }
}
@media only screen and (min-width: 767px) {
  .headers .auth-button {
    margin-left: 30px;
  }
}
