.reviewDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .reviewDetails {
    flex-direction: column;
  }
}
.reviewDetails__item {
  margin: 5px 0;
}
.reviewDetails__heading {
  display: block;
  padding: 0;
  color: #9c9c9c;
  font-size: 1.1rem;
  margin-bottom: 3px;
}
