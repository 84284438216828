@import "@/styles/scss/_variables.scss";






























































































































.entry__step {
  height: auto;
  padding-bottom: 75px;

  .onboarding__stepControls {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;

    @media (min-width: $desktop) {
      display: none;
    }

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      box-shadow: inset 0 -10px 10px -11px $cl-black-alpha-15;
      content: '';
    }
  }

  &Headline {
    margin-bottom: 32px;

    @media (max-width: $tablet - 1) {
      margin-bottom: 20px;
    }
  }

  &HeadlineSecond {
    margin-bottom: 24px;
  }

  &Benefit {
    position: relative;
    margin-bottom: 10px;
    @media (min-width: $desktop) {
      margin-bottom: 20px;
    }
  }

  &Row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet - 1) {
      flex-direction: column-reverse;
    }
  }

  &Column {
    width: 310px;

    @media (max-width: $tablet - 1) {
      width: 100%;
    }
  }

  &Login {
    margin-top: 24px;
    text-align: center;
    color: $cl-mako;

    span {
      color: $cl-radical-red;
      cursor: pointer;
    }

    @media (max-width: $tablet - 1) {
      margin-top: 40px;
      border-top: 1px solid $cl-mako-alpha-15;
      padding: 24px 0 60px;
    }
  }

  &Terms {
    color: $cl-mako;

    span {
      color: $cl-radical-red;
      cursor: pointer;
    }
  }

  .benefit {
    &__icon {
      position: absolute;
      top: 5px;
      left: 0;
      width: 15px;
      height: 15px;
    }

    &__label {
      padding-left: 30px;
    }
  }
}
