@import "@/styles/scss/_variables.scss";


































































.center.aligned.content {
  height: 150px;
  position: relative;
}

.ui.carousel {
  min-height: 435px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .item {
    display: flex;
    justify-content: center;
  }
}

.carousel-nav {
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: $tablet) {
    position: absolute;
  }
  &.carousel-nav-prev {
    left: -15px;
    @media (min-width: $tablet) {
      left: 0;
    }
  }

  &.carousel-nav-next {
    right: -15px;
    @media (min-width: $tablet) {
      right: 0;
    }
  }
}
