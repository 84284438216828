@import "@/styles/scss/_variables.scss";

























.onboarding__step {
  &Headline {
    margin-bottom: 24px;
  }
}

.aj-button {
  max-width: 400px;
  margin-top: 24px;
}
