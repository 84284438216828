
























.ui.container {
  @media only screen and (max-width: 769px) {
    font-size: 12px;
  }
}

h1.ui.header {
  font-size: 1.6rem;
  padding-top: 1.2em;

  @media only screen and (min-width: @largestMobileScreen) {
    font-size: 2.4rem;
  }
}

h2.header {
  font-size: 1.4em;
  margin-bottom: 0;
}

span {
  font-size: 0.9em;
  font-weight: 300;
  opacity: 0.5;
  padding-bottom: 5px;
}

@colors: #FAD961, #FDBE31, #FEA323, #FE8A16, #FF7A0E, #F76B1C;

.progress-percent {
  font-size: 1.3em;
  padding: 6px;
  color: #FAD961;

  .class-loop(length(@colors));

  .class-loop(@n, @i: 1) when (@i =< @n) {
    &.variant@{i} {
      color: extract(@colors, @i);
    }
    .class-loop(@n, (@i + 1));
  }
}

// with context
.no-headers {
  & > h1,
  & > span {
    display: none;
  }

  .ui.header.white {
    color: @lightBlack !important;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
