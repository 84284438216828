.filters .ui.label.filter {
  margin: 4px;
}
.filters.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.filters.column .ui.label.filter {
  text-align: center !important;
}
