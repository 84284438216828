@import "@/styles/scss/_variables.scss";
































.partners {
  &__itemWrapper {
    display: flex;
    flex: 0 0 100%;
    max-height: 135px;
    margin-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
    @media (min-width: $desktop-small) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      margin-bottom: 25px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
}

.partnerItem {
  &__logo {
    margin-right: 15px;

    .aj-partnerLogo__fallbackLogo {
      width: 50px;
      height: 50px;
      font-size: 30px;
    }
  }
  &__content {
    color: $cl-mako;
  }
  &__jobsCount {
    &:hover {
      text-decoration: underline;
    }
  }
}
