.progress-wrapper {
  margin: 6px auto 0;
  width: 300px;
  height: 8px;
  max-width: 100%;
  background: linear-gradient(to right, #FAD961, #F76B1C);
  border-radius: 4px;
  overflow: hidden;

  & > .progress-bar {
    float: right;
    height: 100%;
    width: 100%;
    background-color: @white;
    transition: width 2s;
    border-radius: 0 4px 4px 0;
  }
}
