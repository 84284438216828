@import "@/styles/scss/_variables.scss";



































.cookieNotice {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 25px 16px;
  background-color: $cl-white;
  box-shadow: 0 -4px 8px $cl-black-alpha-10;
  @media (min-width: $mobile-big) {
    justify-content: center;
  }
  @media (min-width: $desktop) {
    flex-direction: row;
  }

  &.-visible {
    display: flex;
  }

  &__text {
    margin-bottom: 20px;
    color: $cl-mako;

    @media (min-width: $desktop) {
      flex-basis: 60%;
      margin: 0 20px 0 0;
      padding-left: 50px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media (min-width: $desktop) {
      flex-direction: row-reverse;
      flex-basis: 40%;
      justify-content: center;
      width: initial;
    }
  }

  &__button {
    width: 100%;
    margin-bottom: 10px;
    @media (min-width: $desktop) {
      width: initial;
      margin: 0 10px 0 0;
    }
  }
}
