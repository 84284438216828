// Text overflow ellipsis for multiple lines
.max-lines(@lines: 2; @line-height: 1.5em) {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines;
  line-height: @line-height;
  max-height: @line-height * @lines; // fallback for non webkit browsers
}
