@import "@/styles/scss/_variables.scss";







































.reviewUgc {
  &__finish {
    text-align: center;
  }
  &__closeButton {
    margin-top: 20px;
  }
}
