@import "@/styles/scss/_variables.scss";



































.get-started {
  &__icon {
    margin-top: 75px;
    width: 400px;
    max-width: 100%;
  }

  &__login {
    span {
      color: $cl-radical-red;
      cursor: pointer;
      font-weight: bold;
    }
  }

  @media (max-width: $tablet - 1) {
    &__icon {
      margin-top: 10vh;
    }

    &__login {
      text-align: center;
    }
  }
}

.onboarding__stepControls {
  position: static;
  margin-top: 40px;
  margin-bottom: 65px;

  button {
    width: 310px;
    max-width: 100%;
  }

  @media (max-width: $tablet - 1) {
    margin-top: -45px;
    margin-bottom: 40px;

    button {
      width: 100%;
    }
  }
}
