.ui.modal {
  padding: 20px 40px;
  max-width: 400px;
}
@media screen and (min-width: 768px) {
  .ui.modal {
    padding: 40px;
  }
}
@media screen and (min-width: 767px) {
  .ui.modal .close {
    right: 0.5em;
    top: 0.5em;
  }
}
