.infographic.ui.grid > .column:not(.row)[class*="no padding"] {
  padding-left: 0;
  padding-right: 0;
}
.infographic.ui.grid > .column:not(.row).separator {
  margin: auto;
  margin-top: 80px;
  padding: 0;
}
.infographic.ui.grid > .column:not(.row) .text {
  font-size: 20px;
}
.infographic.ui.grid.small > .column:not(.row).separator {
  margin-top: 58px;
}
.infographic.ui.grid.small > .column:not(.row) .text {
  font-size: 16px;
}
@media all and (max-width: 767px) {
  .infographic.ui.grid.small-mobile > .column:not(.row) .text {
    font-size: 16px;
  }
}
.infographic.ui.grid.emphasis .text {
  font-weight: bold;
}
