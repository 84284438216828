







































.reviewDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;

  @media (max-width: @largestMobileScreen) {
    flex-direction: column;
  }

  &__item {
    margin: 5px 0;
  }

  &__heading {
    display: block;
    padding: 0;
    color: @darkGrey;
    font-size: 1.1rem;
    margin-bottom: 3px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
