@import "@/styles/scss/_variables.scss";



















.aj-rating__additionalInfo {
  margin-left: 5px;
}
