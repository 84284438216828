/*******************************
         Site Overrides
*******************************/
svg {
  fill: currentColor;
}

strong {
  &.mini {
    font-size: @relativeMini;
  }
  &.tiny {
    font-size: @relativeTiny;
  }
  &.small {
    font-size: @relativeSmall;
  }
  &.medium {
    font-size: @relativeMedium;
  }
  &.large {
    font-size: @relativeLarge;
  }
  &.big {
    font-size: @relativeBig;
  }
  &.huge {
    font-size: @relativeHuge;
  }
  &.massive {
    font-size: @relativeMassive;
  }
}
