@import "@/styles/scss/_variables.scss";

















































$aj-modal__containter-paddingTop: 40px;
$aj-steps__progress-height: 22px;

.resumeStep {
  height: calc(100vh - #{$aj-steps__progress-height} - #{$aj-modal__containter-paddingTop});

  &__onboardingMenu {
    @media screen and (min-width: $desktop) {
      display: none;
    }
  }

  &__action {
    width: 100%;
    max-width: 370px;
    margin-top: 24px;
  }

  &__login {
    margin-top: 32px;
    text-align: center;
    @media screen and (min-width: $desktop) {
      margin-top: 60px;
      text-align: left;
    }

    span {
      color: $cl-radical-red;
      cursor: pointer;
      font-weight: bold;

      @media (max-width: $tablet - 1) {
        &__login {
          text-align: center;
        }
      }
    }
  }
}
