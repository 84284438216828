<template>
  <div class="my-job-offers">
    <loader-indicator :show="isLoading" />
    <template v-if="!isLoading">
      <h2 class="ui center aligned light header">
        {{ translate('profile.jobOffers.header') }}
      </h2>
      <div class="jobOffers ui doubling four stackable spaced cards">
        <partner-review-card
          v-for="offer in myJobOffersSplit.withoutReview"
          :id="idFromSlugs(offer.partner.orgSlug, offer.city.orgSlug)"
          :key="offer.id"
          :offer="offer"
          @reviewed="getOffers"
          @deleted="getOffers($event)"
        />
        <partner-add-card @added="onJobOfferAdded($event)" />
      </div>

      <h4>{{ translate('profile.jobOffers.reviewed.header') }}</h4>
      <div
        v-if="myJobOffers.length"
        class="jobOffers ui doubling four stackable spaced cards"
      >
        <partner-review-card
          v-for="offer in myJobOffersSplit.withReview"
          :key="offer.id"
          :offer="offer"
        />
      </div>
      <p v-else>
        {{ translate('profile.jobOffers.noData') }}
      </p>
      <container-wrapper>
        <router-link
          :to="{ name: 'profile-user' }"
          class="link small"
          v-html="translate('profile.nav.profileTab')"
        />
      </container-wrapper>
    </template>
  </div>
</template>

<style scoped>
  .header:first-child {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .link.small {
    font-size: 1rem;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex';
import LoaderIndicator from '@/js/components/ui/loader-indicator';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import { seoTitle } from '../../helpers/seo';
import jobOffersService from '../../services/job-offers';
import userJobOffersService from '../../services/user-job-offers';
import PartnerReviewCard from '../../components/cards/partner-review-card';
import PartnerAddCard from '../../components/cards/partner-add-card';

export default {
  name: 'EmployersData',

  components: {
    LoaderIndicator,
    PartnerAddCard,
    PartnerReviewCard,
    ContainerWrapper,
  },

  data () {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapState('jobOffers', ['myJobOffers']),

    myJobOffersSplit () {
      return {
        withReview: this.myJobOffers.filter(jobOffer => !!jobOffer.review),
        withoutReview: this.myJobOffers.filter(jobOffer => !jobOffer.review),
      };
    },
  },

  watch: {
    myJobOffers (a, b) {
      if (b.length !== 0 && a.length !== b.length) {
        this.getOffers();
      }
    },
  },

  created () {
    seoTitle(this.translate('profile.nav.employers'));
  },

  mounted () {
    this.getOffers();
  },

  methods: {
    ...mapActions('jobOffers', ['getMyJobOffers']),

    /**
       * Creates HTML ID from arguments and prefixes it.
       * In query selector use '#' as first argument.
       *
       * @returns {string}
       */
    idFromSlugs () {
      const args = Array.from(arguments);
      args[0] = args[0] === '#' ? '#jo' : `jo_${args[0]}`;

      return args.join('_');
    },

    onJobOfferAdded (jobOffer) {
      if (!jobOffer || !jobOffer.city || !jobOffer.partner) {
        return;
      }

      this.$router.push({
        name: 'profile-employers',
        query: { city_slug: jobOffer.city.orgSlug, partner_slug: jobOffer.partner.orgSlug },
        params: { language: this.language },
      });

      this.getOffers();
    },

    async getOffers (offerId = 0) {
      const { query } = this.$route;

      if (query.partner_slug && query.city_slug && !offerId) {
        const jobOffer = await jobOffersService.getOffers({
          city_slug: query.city_slug,
          partner_slug: query.partner_slug,
        });
        if (jobOffer.length) {
          await userJobOffersService.postUserJobOffer(jobOffer[0].id);
        }
      }

      await this.getMyJobOffers();
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
  .jobOffers >>> .ui.selection.dropdown > .search.icon {
    line-height: .8em !important;
  }
</style>
