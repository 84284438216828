@import "@/styles/scss/_variables.scss";





























.jobOffer {
  &__empty {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    @media (min-width: $desktop) {
      max-width: $desktop-layout-width;
      margin: 0 auto;
      background-color: $cl-white;
    }
    @media (min-width: $desktop-big) {
      max-width: $desktop-big-layout-width;
    }
  }
}
