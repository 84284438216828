@import "@/styles/scss/_variables.scss";




































.city {
  &__hero {
    background-color: $cl-dawn-pink;
    padding: 98px 15px 48px;
    @media (max-width: $mobile) {
      padding-top: 70px;
      padding-bottom: 30px;
    }
  }

  &__wrapper {
    @media (min-width: $desktop) {
      max-width: $desktop-big-layout-width;
      margin: 0 auto;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 20px;
    color: $cl-mako;
  }

  &__search {
    max-width: 480px;
    position: relative;

    @media (max-width: $tablet) {
      max-width: 350px;
    }
  }
}
