@import "@/styles/scss/_variables.scss";





























































































.cookiePreferences {
  &__headline {
    margin-bottom: 35px;

    @media (min-width: $tablet) {
      margin-bottom: 30px;
    }
  }

  &__choices {
    margin-top: 10px;
  }
}
