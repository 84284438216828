/*******************************
             Icon
*******************************/

/* Icon Variables */
@mini: 0.4em;
@tiny: 0.5em;
@small: 0.75em;
@normal: 1em;
@large: 1.5em;
@big: 2em;
@huge: 4em;
@massive: 8em;

@opacity: 1;
@distanceFromText: 0.4rem;

/* Variations */
@linkOpacity: 0.8;
@linkDuration: 0.3s;
@loadingDuration: 2s;

@circularSize: 2em;
@circularPadding: 0.5em 0em;
@circularShadow: 0em 0em 0em 0.1em rgba(0, 0, 0, 0.1) inset;

@borderedSize: 2em;
@borderedVerticalPadding: ((@borderedSize - @normal) / 2);
@borderedHorizontalPadding: 0em;
@borderedShadow: 0em 0em 0em 0.1em rgba(0, 0, 0, 0.1) inset;

@cornerIconSize: 0.45em;
@cornerIconStroke: 1px;
@cornerIconShadow:
    -@cornerIconStroke -@cornerIconStroke 0 @white,
    @cornerIconStroke -@cornerIconStroke 0 @white,
  -@cornerIconStroke  @cornerIconStroke 0 @white,
  @cornerIconStroke  @cornerIconStroke 0 @white
;
@cornerIconInvertedShadow:
    -@cornerIconStroke -@cornerIconStroke 0 @black,
    @cornerIconStroke -@cornerIconStroke 0 @black,
  -@cornerIconStroke  @cornerIconStroke 0 @black,
  @cornerIconStroke  @cornerIconStroke 0 @black
;

/*******************************
             Icon
*******************************/

svg.icon {
  display: inline-block;
  opacity: @opacity;

  margin: 0em @distanceFromText 0em 0em;

  width: @normal;
  height: @normal;
}

/*******************************
             States
*******************************/

svg.icon.hover {
  opacity: 1 !important;
}

svg.icon.active {
  opacity: 1 !important;
}

svg.disabled.icon {
  opacity: @disabledOpacity !important;
}


/*******************************
           Variations
*******************************/

/*-------------------
         Link
--------------------*/

svg.link.icon, .link.icons {
  cursor: pointer;
  opacity: @linkOpacity;
  transition: opacity @defaultDuration @defaultEasing;
}
svg.link.icon:hover, .link.icons:hover {
  opacity: 1 !important;
}

/*-------------------
      Circular
--------------------*/

svg.circular.icon {
  border-radius: 500em !important;
  line-height: 1 !important;

  padding: @circularPadding !important;
  box-shadow: @circularShadow;

  width: @circularSize !important;
  height: @circularSize !important;

  &.small {
    width: 1.2em !important;
    height: 1.2em !important;
    padding: 0.2em !important;
  }

  &.medium {
    width: 2.33em !important;
    height: 2.33em !important;
  }

  &.huge {
    width: 8em !important;
    height: 8em !important;
    padding: 1.5em !important;
  }
}
svg.circular.inverted.icon {
  border: none;
  box-shadow: none;
}

svg.circular.dark.icon {
  box-shadow: none;
  border: 1px solid;
  background: transparent;
}

/*-------------------
      Rotated
--------------------*/

svg.rotated.icon,
svg.right.rotated.icon,
svg.clockwise.rotated.icon {
  transform: rotate(90deg);
}

svg.left.rotated.icon,
svg.counterclockwise.rotated.icon {
  transform: rotate(-90deg);
}

/*-------------------
      Inverted
--------------------*/

/* Inverted Shapes */
svg.inverted.circular.icon,
svg.icon.inverted.circular.primary {
  background-color: @black !important;
  color: @white !important;
}

svg.inverted.icon {
  color: @white;
}


/*-------------------
       Colors
--------------------*/

/* Red */
svg.red.icon {
  color: @red !important;
}

/* Orange */
svg.orange.icon {
  color: @orange !important;
}

/* Green */
svg.green.icon {
  color: @green !important;
}
svg.inverted.green.icon {
  color: @lightGreen !important;
}
svg.inverted.bordered.green.icon,
svg.inverted.circular.green.icon {
  background-color: @green !important;
  color: @white !important;
}

/* Blue */
svg.blue.icon {
  color: @blue !important;
}
svg.inverted.blue.icon {
  color: @lightBlue !important;
}
svg.inverted.bordered.blue.icon,
svg.inverted.circular.blue.icon {
  background-color: @blue !important;
  color: @white !important;
}

/* Grey */
svg.grey.icon {
  color: @grey !important;
}
svg.inverted.grey.icon {
  color: @lightGrey !important;
}
svg.inverted.bordered.grey.icon,
svg.inverted.circular.grey.icon {
  background-color: @grey !important;
  color: @white !important;
}

/* Black */
svg.black.icon {
  color: @black !important;
}

/* white */

svg.inverted.bordered.white.grey.icon,
svg.inverted.circular.white.grey.icon {
  background-color: @white !important;
  color: @darkGrey !important;
}

/* White darkblue */
svg.icon[class*="white darkblue"] {
  background-color: @white;
  color: @darkBlue !important;
}

svg.icon.circular[class*="white darkblue"] {
  background-color: @white;
  color: @darkBlue !important;
}

/* lightblack */
svg.icon.inverted.circular[class*="lightblack"] {
  background-color: @lightBlack !important;
}

/* Blue grey */
svg.icon[class*="blue grey"] {
  background-color: transparent;
  color: @blueGrey !important;
}
svg.icon.inverted[class*="blue grey"] {
  color: @lightBlack !important;
}
svg.icon.inverted.bordered[class*="blue grey"],
svg.icon.inverted.circular[class*="blue grey"] {
  background-color: @blueGrey !important;
  color: @primaryColor !important;
}

/* Special */
svg.icon.special {
  color: @special !important;
}
svg.icon.inverted.special {
  color: @lightSpecial !important;
}
svg.icon.inverted.bordered.special,
svg.icon.inverted.circular.special {
  background-color: @special !important;
  color: @white !important;
}

/*-------------------
        Sizes
--------------------*/

svg.min.icon,
svg.min.icons {
  line-height: 1;
  width: @mini;
  height: @mini;
}
svg.tiny.icon,
svg.tiny.icons {
  line-height: 1;
  width: @tiny;
  height: @tiny;
}
svg.small.icon,
svg.small.icons {
  line-height: 1;
  width: @small;
  height: @small;
}
svg.icon,
svg.icons {
  width: @normal;
  height: @normal;
}
svg.large.icon,
svg.large.icons {
  line-height: 1;
  vertical-align: middle;
  width: @large;
  height: @large;
}
svg.big.icon,
svg.big.icons {
  line-height: 1;
  vertical-align: middle;
  width: @big;
  height: @big;
}
svg.huge.icon,
svg.huge.icons {
  line-height: 1;
  vertical-align: middle;
  width: @huge;
  height: @huge;
}
@media only screen and (min-width: @tabletBreakpoint) {
  svg.huge-desktop.icon,
  svg.huge-desktop.icons {
    line-height: 1;
    vertical-align: middle;
    width: @huge;
    height: @huge;
  }
}
svg.massive.icon,
svg.massive.icons {
  line-height: 1;
  vertical-align: middle;
  width: @massive;
  height: @massive;
}

/*******************************
            Groups
*******************************/

svg.icons {
  display: inline-block;
  position: relative;
  line-height: 1;
}

svg.icons .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0;
}

svg.icons .icon:first-child {
  position: static;
  width: auto;
  height: auto;
  vertical-align: top;
  transform: none;
  margin-right: @distanceFromText;
}

/*******************************
            Other Options
*******************************/

a.hole {
  svg.icon.inverted.bordered,
  svg.icon.inverted.circular {
    color: @primaryColor !important;
  }

  svg.icon.circular[class*="blue white"] {
    background-color: @blue;
    color: @white !important;
  }

  &:hover {
    opacity: .8;
  }
}

.ui.icon.input>i.icon {
  left: 10px;
  top: 12px;
}
