@import "@/styles/scss/_variables.scss";




















.partners__toolbar {
  .toolbar {
    &__filtersButton {
      width: auto;

      &.aj-button {
        padding-left: 1.714rem;
        padding-right: 1.714rem;
      }
    }
    &__buttonIcon {
      margin-right: 10px;
    }
  }
}
