@media only screen and (max-width: 769px) {
  .ui.container {
    font-size: 12px;
  }
}
h1.ui.header {
  font-size: 1.6rem;
  padding-top: 1.2em;
}
@media only screen and (min-width: 767px) {
  h1.ui.header {
    font-size: 2.4rem;
  }
}
h2.header {
  font-size: 1.4em;
  margin-bottom: 0;
}
span {
  font-size: 0.9em;
  font-weight: 300;
  opacity: 0.5;
  padding-bottom: 5px;
}
.progress-percent {
  font-size: 1.3em;
  padding: 6px;
  color: #FAD961;
}
.progress-percent.variant1 {
  color: #FAD961;
}
.progress-percent.variant2 {
  color: #FDBE31;
}
.progress-percent.variant3 {
  color: #FEA323;
}
.progress-percent.variant4 {
  color: #FE8A16;
}
.progress-percent.variant5 {
  color: #FF7A0E;
}
.progress-percent.variant6 {
  color: #F76B1C;
}
.no-headers > h1,
.no-headers > span {
  display: none;
}
.no-headers .ui.header.white {
  color: #4a4a4a !important;
}
