/*******************************
         Site Overrides
*******************************/
.ui.grid {
  .column {
    &.condensed {
      padding: 0.1em;
    }

    @media only screen and (min-width: @tabletBreakpoint) {
      &[class*="desktop vertical separated"] {
        border-right: 1px solid @grey;
      }
    }
    &[class*="horizontal separated"] {
      border-bottom: 1px solid @grey;
    }
  }

}

// Hiding on mobile only
@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {
  .ui.grid > .row > [class*="hidden mobile"].column,
  .ui.grid > .column.row > [class*="hidden mobile"].column,
  .ui.grid > [class*="hidden mobile"].column,
  .ui.grid > [class*="hidden mobile"].column,
  .ui.column.grid > [class*="hidden mobile"].column {
    display: none;
  }
}

// Force center on devices

@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {
  .ui.grid > .row > [class*="center aligned mobile"].column,
  .ui.grid > .column.row > [class*="center aligned mobile"].column,
  .ui.grid > [class*="center aligned mobile"].column,
  .ui.grid > [class*="center aligned mobile"].column,
  .ui.column.grid > [class*="center aligned mobile"].column {
    text-align: center !important;
  }

  .ui.grid.stretched > [class*="center aligned mobile"].column {
    align-self: center !important;
  }
}

@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  .ui.grid > .row > [class*="center aligned tablet"].column,
  .ui.grid > .column.row > [class*="center aligned tablet"].column,
  .ui.grid > [class*="center aligned tablet"].column,
  .ui.column.grid > [class*="center aligned tablet"].column {
    text-align: center !important;
  }

  .ui.grid.stretched > [class*="center aligned tablet"].column {
    align-self: center !important;
  }
}

@media only screen and (min-width: @computerBreakpoint) {
  .ui.grid > .row > [class*="center aligned computer"].column,
  .ui.grid > .column.row > [class*="center aligned computer"].column,
  .ui.grid > [class*="center aligned computer"].column,
  .ui.column.grid > [class*="center aligned computer"].column {
    text-align: center !important;
  }

  .ui.grid.stretched > [class*="center aligned computer"].column {
    align-self: center !important;
  }
}

@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  .ui.grid > .row > [class*="center aligned large screen"].column,
  .ui.grid > .column.row > [class*="center aligned large screen"].column,
  .ui.grid > [class*="center aligned large screen"].column,
  .ui.column.grid > [class*="center aligned large screen"].column {
    text-align: center !important;
  }

  .ui.grid.stretched > [class*="center aligned large screen"].column {
    align-self: center !important;
  }
}

@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  .ui.grid > .row > [class*="center aligned widescreen"].column,
  .ui.grid > .column.row > [class*="center aligned widescreen"].column,
  .ui.grid > [class*="center aligned widescreen"].column,
  .ui.column.grid > [class*="center aligned widescreen"].column {
    text-align: center !important;
  }

  .ui.grid.stretched > [class*="center aligned widescreen"].column {
    align-self: center !important;
  }
}

// Force left on devices

@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {
  .ui.grid > .row > [class*="left aligned mobile"].column,
  .ui.grid > .column.row > [class*="left aligned mobile"].column,
  .ui.grid > [class*="left aligned mobile"].column,
  .ui.column.grid > [class*="left aligned mobile"].column {
    text-align: left !important;
  }

  .ui.grid.stretched > [class*="center aligned mobile"].column {
    align-self: left !important;
  }
}

@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  .ui.grid > .row > [class*="left aligned tablet"].column,
  .ui.grid > .column.row > [class*="left aligned tablet"].column,
  .ui.grid > [class*="left aligned tablet"].column,
  .ui.column.grid > [class*="left aligned tablet"].column {
    text-align: left !important;
  }

  .ui.grid.stretched > [class*="center aligned tablet"].column {
    align-self: left !important;
  }
}

@media only screen and (min-width: @computerBreakpoint) {
  .ui.grid > .row > [class*="left aligned computer"].column,
  .ui.grid > .column.row > [class*="left aligned computer"].column,
  .ui.grid > [class*="left aligned computer"].column,
  .ui.column.grid > [class*="left aligned computer"].column {
    text-align: left !important;
  }

  .ui.grid.stretched > [class*="center aligned computer"].column {
    align-self: left !important;
  }
}

@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  .ui.grid > .row > [class*="left aligned large screen"].column,
  .ui.grid > .column.row > [class*="left aligned large screen"].column,
  .ui.grid > [class*="left aligned large screen"].column,
  .ui.column.grid > [class*="left aligned large screen"].column {
    text-align: left !important;
  }

  .ui.grid.stretched > [class*="center aligned large screen"].column {
    align-self: left !important;
  }
}

@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  .ui.grid > .row > [class*="left aligned widescreen"].column,
  .ui.grid > .column.row > [class*="left aligned widescreen"].column,
  .ui.grid > [class*="left aligned widescreen"].column,
  .ui.column.grid > [class*="left aligned widescreen"].column {
    text-align: left !important;
  }

  .ui.grid.stretched > [class*="center aligned widescreen"].column {
    align-self: left !important;
  }
}

// Force roght on devices

@media only screen and (min-width: @mobileBreakpoint) and (max-width: @largestMobileScreen) {
  .ui.grid > .row > [class*="right aligned mobile"].column,
  .ui.grid > .column.row > [class*="right aligned mobile"].column,
  .ui.grid > [class*="right aligned mobile"].column,
  .ui.column.grid > [class*="right aligned mobile"].column {
    text-align: right !important;
  }

  .ui.grid.stretched > [class*="center aligned mobile"].column {
    align-self: right !important;
  }
}

@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  .ui.grid > .row > [class*="right aligned tablet"].column,
  .ui.grid > .column.row > [class*="right aligned tablet"].column,
  .ui.grid > [class*="right aligned tablet"].column,
  .ui.column.grid > [class*="right aligned tablet"].column {
    text-align: right !important;
  }

  .ui.grid.stretched > [class*="center aligned tablet"].column {
    align-self: right !important;
  }
}

@media only screen and (min-width: @computerBreakpoint) {
  .ui.grid > .row > [class*="right aligned computer"].column,
  .ui.grid > .column.row > [class*="right aligned computer"].column,
  .ui.grid > [class*="right aligned computer"].column,
  .ui.column.grid > [class*="right aligned computer"].column {
    text-align: right !important;
  }

  .ui.grid.stretched > [class*="center aligned computer"].column {
    align-self: right !important;
  }
}

@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  .ui.grid > .row > [class*="right aligned large screen"].column,
  .ui.grid > .column.row > [class*="right aligned large screen"].column,
  .ui.grid > [class*="right aligned large screen"].column,
  .ui.column.grid > [class*="right aligned large screen"].column {
    text-align: right !important;
  }

  .ui.grid.stretched > [class*="center aligned large screen"].column {
    align-self: right !important;
  }
}

@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  .ui.grid > .row > [class*="right aligned widescreen"].column,
  .ui.grid > .column.row > [class*="right aligned widescreen"].column,
  .ui.grid > [class*="right aligned widescreen"].column,
  .ui.column.grid > [class*="right aligned widescreen"].column {
    text-align: right !important;
  }

  .ui.grid.stretched > [class*="center aligned widescreen"].column {
    align-self: right !important;
  }
}
