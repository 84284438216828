



































.slides {
  &-image {
    width: 100%;
    height: 127px;
  }

  &-info {
    padding: 25px;

    &-title {
      color: @black;
      display: block;
      margin-bottom: 10px;
    }

    &-count {
      color: @darkGrey;
      margin-bottom: 0;
    }

    &-header {
      line-height: 1;
      font-size: 18px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
