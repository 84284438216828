@import "@/styles/scss/_variables.scss";








































.profile-container {
  position: relative;
}
.categories .category {
  margin: 2px;
  cursor: pointer;
}
