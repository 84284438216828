@import "@/styles/scss/_variables.scss";


























.faq {
  &__heading {
    margin-bottom: 15px;
  }

  &__item {
    margin-bottom: 15px;

    img {
      max-width: 100%;
    }
  }

  &__question {
    margin-bottom: 5px;
  }
}
