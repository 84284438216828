@import "@/styles/scss/_variables.scss";



















































































































$jobOffer-content-desktop-width: $desktop-layout-width;
$jobOffer-content-desktop-big-width: $desktop-big-layout-width;
$jobOffer-main-desktop-side-padding: 38px;

.jobOffer__main {
  background-color: $cl-dawn-pink-lighten-8;
  padding: 20px 15px;

  @media (min-width: $desktop-small) {
    padding: 20px $jobOffer-main-desktop-side-padding 30px;
    border-radius: 16px;
  }
}

.main {
  &__headlineWrapper {
    transition: all .2s ease-in;

    &.-sticky {
      /*
        these two "!important"s are needed here because
        'left' and 'width' styles are set by JS (vue sticky library),
        so to override them we use "!important"s
      */
      left: 0 !important;
      width: 100% !important;
      background-color: $cl-dawn-pink-lighten-8;
      padding: 15px 0;
    }
  }

  &__headline {
    .main__headlineWrapper.-sticky & {
      max-width: calc(#{$jobOffer-content-desktop-width} - #{$jobOffer-main-desktop-side-padding}
      - #{$jobOffer-main-desktop-side-padding});
      margin: 0 auto;

      @media (min-width: $desktop-big) {
        max-width: calc(#{$jobOffer-content-desktop-big-width}
        - #{$jobOffer-main-desktop-side-padding} - #{$jobOffer-main-desktop-side-padding});
      }
    }

    @media (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.-extended {
      @media (min-width: $desktop-small) {
        flex-direction: column;
        align-items: normal;
      }

      .main__apply {
        @media (min-width: $desktop-small) {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        .aj-button {
          @media (min-width: $desktop-small) {
            width: 50%;
          }
        }
      }
    }
  }

  &__headlineInfo {
    display: flex;
  }

  &__partnerLogo {
    margin: 5px 15px 0 0;
  }

  &__title {
    .main__headlineWrapper.-sticky & {
      @media (min-width: $desktop-small) {
        font-size: 24px;
      }
    }
  }

  &__apply {
    @media (max-width: $tablet) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 50;
      padding: 14px 18px;
      background: $cl-white;
      box-shadow: 0px -4px 8px $cl-black-alpha-10;
    }
    @media (min-width: $tablet) {
      min-width: 240px;
    }
    @media (min-width: $desktop-small) {
      min-width: 280px;
    }
  }

  &__openInNewTab {
    display: flex;
    align-items: center;
    color: $cl-radical-red;

    svg {
      margin-right: 5px;
    }
  }

  &__info {
    position: relative;
    margin-top: 15px;
    @media (min-width: $desktop-small) {
      display: flex;
      justify-content: space-between;
      padding-top: 25px;
    }

    &::before {
      @media (min-width: $desktop-small) {
        position: absolute;
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        border-top: 1px solid $cl-mako-alpha-15;
        content: '';
      }
    }
  }
}

.info {
  &__location,
  &__posted {
    display: flex;
    align-items: center;
    color: $cl-mako-lighten-32;
    &Icon {
      margin-right: 5px;
      fill: $cl-mako-lighten-32;
    }
  }
  &__location {
    margin-bottom: 5px;
  }
}
