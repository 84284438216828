











































































.city-selector {
  position: relative;
  width: 100%;

  &-request {
    position: absolute;
    top: -75px;
    border-radius: 24px;
    width: 100%;
    background: @white;
    padding: 10px 15px;
    color: @blue;
    text-align: left;
    z-index: 9;

    &-info {
      margin-bottom: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-action {
      cursor: pointer;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
