@import "@/styles/scss/_variables.scss";

















.signInStep {
  &.entry__step {
    @media screen and (min-width: $desktop) {
      margin: 0 auto;
    }
  }

  .socialButtons {
    flex-direction: column;

    &__button {
      margin-bottom: 15px;
    }
  }

  .aj-input {
    min-width: auto;
  }
}
