/*******************************
         Site Overrides
*******************************/
.ui.modal {
  &.mobile-fit {
    max-height: 85vh;
    overflow: auto;

     .content-centered {
      display: flex;
      flex-direction: column;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      max-height: 100%;
      overflow: auto;
      border-radius: 0;
      margin: 0 !important;
      width: 100%;
    }

    @media all and (max-width: @largestMobileScreen) {
      > .content {
        padding: 0 !important;
      }
    }
  }

  .close {
    z-index: @zIndex + 1;

    top: 15px;
    right: 15px;
  }

  > .content {
    background-color: transparent;
    padding: 0;

    > * {
      padding: @relativeLarge;
    }

    .even-z {
      & > * {
        z-index: @zIndex;
      }
    }

    [class*="auth container"] {
      min-width: 300px;
      max-width: 328px;

      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: @largestMobileScreen) {
        padding-top: 0;
        align-items: flex-start;
        align-self: center;
        flex: 1 1 auto;
      }
    }

  }
}
