@import "@/styles/scss/_variables.scss";















































































.lowOnCost {
  padding: 0;

  &__header {
    margin-bottom: 20px;
  }

  &__choose {
    display: flex;
    justify-content: center;
    .aj-button {
      margin: 0 20px;
    }
  }

  &__input {
    .aj-input__textField {
      max-width: 150px;
      display: inline-block;
      margin-right: 10px;
      input {
        width: 100%;
      }
    }
  }

  &__submit {
    display: block;
    width: 100%;
    margin: 30px auto 0;
    @media (min-width: $tablet) {
      width: auto;
    }
  }
}
