.avatar-holder {
  display: inline-block;
  height: 140px;
}
.avatar-holder .menu {
  margin-top: 10px;
}
.avatar-holder .menu .small.link {
  font-size: 0.92857143em;
}
.ui.stackable.grid {
  margin: 0;
}
.ui.grid > .row > .column {
  padding: 20px;
}
.ui.grid > .row > .column.four.wide {
  display: flex;
  flex-direction: column;
  padding-top: 30px !important;
  padding-right: 10px;
  padding-bottom: 10px !important;
  padding-left: 40px;
}
.ui.grid > .row > .column.four.wide img {
  margin: 0 auto;
}
.ui.grid > .row > .column.four.wide > p {
  font-size: 14px;
  opacity: 0.5;
  padding: 15px 0;
}
.ui.grid > .row > .column.seven.wide {
  box-shadow: none;
}
.header:first-child {
  margin-top: 15px;
}
.fixed-height {
  min-height: 80px;
}
