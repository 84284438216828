// override variables for application here, example:
// $cl-radical-red: red;

// importing variables default values from @appjobs/ui library
@import '@appjobs/ui/src/styles/_variables.scss';

// app variables

$cl-greyF9: #f9fafb;

$desktop-layout-width: 1125px;
$desktop-big-layout-width: 1280px;

$ajCheckboxInput-checked-label-before-background: blue;
