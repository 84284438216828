@import "@/styles/scss/_variables.scss";




























.auth__terms {
  margin: 18px auto;
}
