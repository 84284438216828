@import "@/styles/scss/_variables.scss";





















































.partner__filters {
  margin-bottom: 40px;
}
.partnerFilters {
  &__subtitle {
    margin-bottom: 10px;
  }
  &__item {
    margin: 0 15px 15px 0;
  }
}
