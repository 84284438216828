










































































































.masthead {
  h1.ui.header {
    font-size: 1.6rem;
    padding: 50px 12px 5px;
    margin-bottom: 10px;

    @media only screen and (min-width: 769px) {
      font-size: 2.4rem;
    }
  }

  span.ui.light.header {
    font-size: 1em;
    color: @blueGrey;
  }
}

.ui.container.desktop {
  padding-top: 0;
  margin-top: 5px;
}

.ui.container.text.narrow {
  margin-top: 2em;
}

.city-selector {
  margin: 0 auto;
  max-width: 500px;
}

.ui.cards.mobile {
  position: sticky;
  position: -webkit-sticky;

  & > .card {
    width: 100%;
    margin-bottom: -12px;
  }

  .ui.header {
    font-size: 16px;
  }
}

.city-rating {
  display: inline-flex;
  text-align: left;
  padding: 20px 0;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

.city-rating__label {
  font-size: 1rem;
  line-height: 1;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
