@import "../../../user-defined/category-definitions";

/*******************************
         Site Overrides
*******************************/
.ui.label {
  border-radius: 15px;

  &.middle.aligned {
    vertical-align: baseline !important;
  }

  &.state {
    .categoryFilterColor(@darkGrey);

    &.basic {
      border: none;
      border-radius: 25px;
      .categoryFilterInvertedColor(@darkGrey, transparent);

      &:hover {
        .listCategoryInvertedFilter();
      }
    }

    &.active {
      box-shadow: none;
      .categoryFilterColor(#76BDE8);
      .listCategoryFilter();
      &:hover {
        box-shadow: none;
      }
    }
  }
}
