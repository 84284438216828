@import "@/styles/scss/_variables.scss";












.ui__divider {
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid $cl-mako-alpha-15;
  }
}

.divider__text {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  background: $cl-white;
}
