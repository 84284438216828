/*******************************
         Site Overrides
*******************************/
.ui.container {
  &[class*="desktop sized"] {
    padding-top: @relativeMassive;
    padding-bottom: @relativeMassive;

    @media only screen and (min-width: @tabletBreakpoint) {
      padding-top: (@relativeMassive * 3);
    }
  }

  &[class*="no bottom desktop sized"] {
    padding-bottom: 0;
  }

  &.dark {
    background-color: @grey;
    padding: @mobileGutter 0;
  }
  &.fluid {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: @computerMinimumGutter;
    padding-right: @computerMinimumGutter;
    &:not(.full) {
      max-width: @largestLargeMonitor !important;
    }
  }

  &.subheader {
    position: relative;

    &.medium {
      min-height: 200px;

      @media only screen and (min-width: @tabletBreakpoint) {
        min-height: 270px;
      }
    }

    &.flex {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    &.inverted {
      background-color: @primaryColor;
      color: @secondaryColor;
    }

    .item {
      padding: @itemVerticalPadding @itemHorizontalPadding;
    }
  }

  &.inverted {
    background-color: @primaryColor;
  }

  &.text {
    font-size: 1em;

    /* Sizes */
    &.large {
      font-size: @relativeLarge;
    }
    &.big {
      font-size: @relativeBig;
    }

    /* Widths */
    &.narrow {
      max-width: 1000px !important;

      margin-left: auto !important;
      margin-right: auto !important;

      @media only screen and (max-width: @largestMobileScreen) {
        margin-left: @mobileNarrowGutter !important;
        margin-right: @mobileNarrowGutter !important;
      }
    }
    &.quite-narrow {
      max-width: @largestMobileScreen !important;

      margin-left: auto !important;
      margin-right: auto !important;

      @media only screen and (min-width: @mobileBreakpoint) {
        padding-left: @itemHorizontalPadding !important;
        padding-right: @itemHorizontalPadding !important;
      }

      &.spaceless {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
    &.very-narrow {
      max-width: 600px !important;

      @media only screen and (max-width: @largestMobileScreen) {
        max-width: 300px !important;
      }
      @media only screen and (min-width: @mobileBreakpoint) {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
  &.relaxed {
    padding-top: @relativeBig;
  }
  &[class*="very relaxed"] {
    padding-top: (@relativeMassive * 2);
  }

  @media only screen and (max-width: @largestTabletScreen) {
    &[class*="desktop only"],
    [class*="desktop only"].item {
      display: none !important;
    }
  }
  @media only screen and (min-width: (@largestTabletScreen + 1)) {
    &[class*="tablet and below"] {
      display: none;
    }
  }

  // Colors
  &.primary {
    color: @primaryColor;
  }

  pre {
    white-space: normal;
  }
}
