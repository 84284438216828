@import "@/styles/scss/_variables.scss";


























































.jobOffers__content {
  padding: 15px;
}
