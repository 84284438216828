






















.wrapper {
  display: block;
  padding: 5px 0;
  cursor: pointer;

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }
  .iconic {
    width: 35px;
    margin: 4px 8px;
    float: left;
  }
  .text {
    color: @textColor;
    font-size: 1rem;
    opacity: 0.6;
    font-weight: lighter;
  }

  .link {
    padding: 0;
    font-size: 1.1rem;
    color: #1F7DB3;
    &:hover {
      text-decoration: underline;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
