



























































.stackable.cards {
  .card {
    padding: 3em;

    .image {
      // Square effect trick https://stackoverflow.com/a/21538018/1949605
      // padding is taking objects width to determine percentage
      &:before {
        content: '';
        padding-top: 100%;
        display: inline-block;
      }

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      text-align: center;

      > .default {
        color: #ccc;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2em;
      }
    }

    > .content {
      > .header {
        color: @primaryColor;
      }
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
