

























































































.ui.menu .item.right {
  flex: 1 1 auto;
  transition: font-size 500ms;
  justify-content: flex-end;
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.logo {
    width: 100px;
  }

  .ui.container.fluid {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.logo-wrapper {
  flex-direction: column;
  .header.item {
    padding: 0;
    height: 100%;
  }
}

.dim {
  display: none;
}

.dim.darkbgr {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.item.right {
  justify-content: flex-end;
}

.item.right > .language {
  display: contents;
}

header.ui.menu {
  flex-wrap: wrap;

  .item {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: @largestMobileScreen) {
  header.ui.inverted.menu {
    .container.fluid {
      padding-left: 0;

      .header.item {
        padding: 0;
      }

      .ui.logo {
        width: 100px;
        height: 32px;
        object-fit: contain;
        margin-left: 15px;
      }
    }
  }

  .item.right.newVariant {
    .top-nav {
      padding-bottom: 10px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
