@colorActive: @blue;
@colorTabs: @darkerGrey;

.tabs {
  &-items {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid @colorTabs;
  }

  &-tab {
    display: inline-block;

    &-link {
      display: block;
      color: @colorTabs;
      font-size: 1.3rem;
      padding: 16px 30px;
    }

    &.active {
      & > a {
        color: @colorActive;
        border-bottom: 3px solid @colorActive;
        position: relative;
        top: 1px;
      }
    }
  }
}
