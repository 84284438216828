.user-menu .mobile-inital {
  display: flex;
  align-items: center;
}
.user-menu .mobile-inital .white {
  margin-left: 20px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu {
  border: 0;
  z-index: 100;
  max-width: 100%;
  right: -10px;
  top: 40px;
  text-align: left;
  width: 300px;
}
@media (min-width: 768px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu::before {
    position: absolute;
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    transform: rotate(45deg);
    z-index: 2;
    top: -0.3em;
    right: 15px;
    bottom: auto;
    left: auto;
    margin-left: 0;
  }
}
.user-menu.ui.ui.ui.dropdown > .new.menu.vertical {
  padding-left: 0;
  box-shadow: -2px 0 16px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu.vertical {
    margin-top: 0;
    height: 105vh;
    overflow: scroll;
  }
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper {
  padding: 24px 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.1);
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper.darker {
  background-color: #f9fafc;
  width: 300px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper.unlogged {
  padding-left: 0;
  padding-right: 0;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper .ui.large.fluid {
  width: 126px;
  height: 40px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper .ui.large.fluid.-primary {
  margin-left: 16px;
  margin-right: 16px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper .ui.large.fluid.-subtleLight {
  margin-right: 16px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper > .profile {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper > .profile .profile-name.row {
  display: flex;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .upper-wrapper > .profile .ui.header.column {
  margin-top: 0;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .mobile.only.closing {
  text-align: right;
  padding-right: 24px;
  color: #495e7a;
  width: 35px;
  height: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .mobile.only.closing span {
  position: absolute;
  right: 41px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .mobile.only.closing .close.icon {
  cursor: pointer;
  margin-right: 0;
  position: absolute;
  right: 17px;
  top: 24px;
  z-index: 15;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .mobile.only.closing .close.icon.black {
  color: #4a4a4a;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .unlogged-user {
  display: flex;
  padding-top: 32px;
  justify-content: space-around;
  align-items: baseline;
}
@media screen and (min-width: 767px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu .unlogged-user {
    padding-top: 0;
  }
}
.user-menu.ui.ui.ui.dropdown > .new.menu .about-profile {
  display: flex;
  justify-content: left;
  align-items: baseline;
  margin: 0;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .ui.small.header {
  margin: 14px;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .ui.small.header:first-of-type {
  margin-left: 0;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .ui.small.header:active {
  color: #f6b026;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .sub.header {
  color: #4a4a4a;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .item.menu-logout {
  height: 64px;
  line-height: 64px;
  color: #4a4a4a;
  display: inline-flex;
  padding-left: 20px !important;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .item.menu-logout .logout-text {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
.user-menu.ui.ui.ui.dropdown > .new.menu .item.menu-logout .logout {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 20px 16px 20px 0;
}
@media screen and (max-width: 767px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu {
    height: 100vh;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .arrow-right-1 {
    width: 16px;
    height: 16px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu.visible {
    position: absolute;
    top: -3px;
    display: flex !important;
    flex-direction: column;
    right: -15px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu {
    width: 300px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu .single-neutral-home {
    width: 30px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.item {
    justify-content: left;
    color: #4a4a4a;
    margin-left: 0;
    border-bottom: 1px solid #f8f8f8;
    padding: 15px 0 13px 16px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.item p.ui.small.header {
    width: 216px;
    height: 19px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.item span.ui.light.sub.header {
    text-transform: inherit;
    width: 216px;
    height: 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #5c779a;
    white-space: initial;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.active {
    border-left: 4px solid #ff3c4d;
    padding-left: 12px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.active p.ui.small.header {
    color: #ff3c4d;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.active svg {
    fill: #ff3c4d;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu .language.item {
    padding: 20px 16px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu .language.item .ui.dropdown.language-selector {
    color: #4a4a4a !important;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu .language.item .ui.dropdown.language-selector:active {
    color: #4a4a4a;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu .language.item .ui.dropdown.language-selector:active > .menu.transition.visible {
    max-height: 50vh;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu__icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 16px;
    fill: #9AABC2;
  }
}
@media screen and (max-width: 375px) {
  .user-menu.ui.ui.ui.dropdown > .new.menu .about-profile {
    padding-left: 0.5em;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .ui.small.header {
    margin: 10px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .about-profile .ui.small.header .sub.header {
    font-size: 12px;
  }
  .user-menu.ui.ui.ui.dropdown > .new.menu .mobile-menu a.item span.ui.light.sub.header {
    font-size: 12px;
  }
}
.user-menu .pin-1 {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 4px;
}
.user-menu .single-neutral-circle,
.user-menu .navigation-menu {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.user-menu .profile-picture {
  width: 56px;
  height: 56px;
  margin: 0 16px 24px 0;
}
.user-menu .profile-avatar {
  width: 56px;
  height: 56px;
  object-fit: contain;
  fill: #ff3c4d;
}
.user-menu .ui.header.column.full-name {
  text-transform: capitalize;
  height: 20px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #232d3a;
  padding-top: 6px;
  white-space: initial;
}
.user-menu .ui.header.column.full-name .sub.header.city-name {
  display: flex;
  align-items: center;
  padding-top: 8px;
  height: 24px;
  font-size: 14px;
}
