















































































.ui.modal.mobile-fit {
  min-height: 250px;

  form {
    padding: 40px;

    button {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: @tabletBreakpoint) {
    .menu.transition {
      max-height: 6rem;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
