@import "@/styles/scss/_variables.scss";






































































$aj-modal__containter-paddingTop: 40px;
$aj-steps__progress-height: 22px;
$aj-steps__controls-height: 75px;

.app {
  &.-cookieOpened {
    .onboarding {
      .onboarding__stepContent {
        padding-bottom: 150px;
      }
      .onboarding__stepControls {
        bottom: 60px;
      }
    }
  }
}

.aj-modal {
  &.-onboarding {
    .aj-modal {
      &__container {
        @media screen and (min-width: $desktop) {
          padding: 0;
        }
      }

      &__content {
        height: 100%;
      }
    }
  }
}

.onboarding {
  height: 100%;
  padding-top: 15px;
  @media screen and (min-width: $desktop) {
    padding-top: 0;
  }

  .aj-steps {
    &__progress {
      position: absolute;
      top: 20px;
      @media screen and (min-width: $desktop) {
        position: initial;
        width: 370px;
        flex: 0 0 auto;
        background-color: $cl-supernova-lighten-16;
      }
    }

    &__progressBar {
      &.-default {
        @media screen and (min-width: $desktop) {
          display: none;
        }
      }

      &.-enhanced {
        display: none;
        @media screen and (min-width: $desktop) {
          display: block;
          padding: 40px 20px 20px 40px;
        }
      }
    }

    &__progressLogo {
      display: block;
      margin-left: 25px;
      fill: $cl-white;
    }

    &__progressSearchIcon {
      display: block;
      margin: 50px 0 10px 25px;
    }

    &__content {
      width: 100%;
      overflow-x: auto;
    }
  }

  &__step {
    height: calc(100vh - #{$aj-steps__progress-height} - #{$aj-modal__containter-paddingTop}
    - #{$aj-steps__controls-height});
    display: flex;
    flex-direction: column;
    max-width: 650px;
    @media screen and (min-width: $tablet) {
      height: calc(100vh - #{$aj-steps__progress-height} - #{$aj-modal__containter-paddingTop});
    }

    @media screen and (min-width: $desktop) {
      height: auto;
      padding-top: 120px;
      margin: 0 40px 0 80px;
    }

    @media screen and (min-width: $desktop-big) {
      margin-left: 185px;
    }

    &Headline {
      color: $cl-mako;
    }

    &Content {
      margin-top: 20px;
      padding-bottom: $aj-steps__controls-height;
      @media screen and (min-width: $tablet) {
        margin-top: 50px;
      }

      &.-scrollable {
        & + .onboarding__stepControls {
          @media screen and (min-width: $tablet) {
            margin-top: 0;
          }

          &::before {
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 10px;
            box-shadow: inset 0 -10px 10px -11px $cl-black-alpha-15;
            content: '';
          }
        }
      }
    }

    &Controls {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background-color: $cl-white;
      @media screen and (min-width: $tablet) {
        margin-top: 50px;
      }

      @media (min-width: $desktop) {
        position: absolute;
        left: auto;
        right: auto;
        width: 654px;
        padding-left: 0;
        padding-right: 0;
        margin-left: -2px;
      }

      .aj-button.-large {
        &.-text {
          @media screen and (max-width: $tablet) {
            padding-left: 0;
            padding-right: 0;
          }
        }

        @media screen and (max-width: $tablet) {
          font-size: 16px;
        }
      }
    }
  }
}
