@import "@/styles/scss/_variables.scss";















































.jobCategoriesStep {
  .aj-pill {
    flex: 0 0 calc(50% - 8px);
    @media (min-width: $tablet) {
      flex: 0 0 calc(33.333333% - 10px);
    }
  }

  .aj-pill-group {
    &__item {
      &:nth-child(2n) {
        margin-right: 0;
      }

      @media (min-width: $tablet) {
        &:nth-child(2n) {
          margin-right: 15px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
