body,html {
  height: 100%
}

html {
  font-size: 14px
}

body {
  margin: 0;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-size: 16px;
  line-height: 1.4285em;
  color: #4a4a4a;
}

body,h1,h2,h3,h4,h5 {
  padding: 0;
  font-family: Muli,Verdana,sans-serif
}

h1,h2,h3,h4,h5 {
  line-height: 1.28571429em;
  margin: calc(2rem - .14286em) 0 1rem;
  font-weight: 700
}

h1 {
  min-height: 1rem;
  font-size: 2rem
}

h2 {
  font-size: 1.71428571rem
}

h3 {
  font-size: 1.28571429rem
}

h4 {
  font-size: 1.07142857rem
}

h5 {
  font-size: 1rem
}

h1:first-child,h2:first-child,h3:first-child,h4:first-child,h5:first-child {
  margin-top: 0
}

h1:last-child,h2:last-child,h3:last-child,h4:last-child,h5:last-child {
  margin-bottom: 0
}

p {
  margin: 0 0 1em;
  line-height: 1.4285em
}

p:first-child {
  margin-top: 0
}

p:last-child {
  margin-bottom: 0
}

a {
  color: #1F7DB3;
  text-decoration: none;
}

a:hover {
  color: #15557A;
  text-decoration: underline;
}

::-webkit-selection {
  background-color: #cce2ff;
  color: #4a4a4a
}

::-moz-selection {
  background-color: #cce2ff;
  color: #4a4a4a
}

::selection {
  background-color: #cce2ff;
  color: #4a4a4a
}

input::-webkit-selection,textarea::-webkit-selection {
  background-color: hsla(0,0%,39.2%,.4);
  color: #4a4a4a
}

input::-moz-selection,textarea::-moz-selection {
  background-color: hsla(0,0%,39.2%,.4);
  color: #4a4a4a
}

input::selection,textarea::selection {
  background-color: hsla(0,0%,39.2%,.4);
  color: #4a4a4a
}

.dark {
  background-color: @grey;
}

.slide-fade-enter-active,
.slide-fade-leave-active,
.fade-enter-active,
.fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: all 1s ease;
}
.slide-leave-active {
  transition: all .3s ease;
}
.slide-enter, .slide-leave-to {
  transform: translate(0, 50px) !important;
  opacity: 0;
}
.slide-enter-to, .slide-leave {
  transform: translate(0, 0);
  opacity: 1;
}

.list-item-enter-active,
.list-item-leave-active,
.list-item-container-enter-active,
.list-item-container-leave-active {
  transition: all 1s ease;
}

.list-item-enter, .list-item-leave-to {
  transform-origin: top;
  transform: scaleY(0) translateY(-100px);
}

.list-item-container-enter, .list-item-container-leave-to {
  margin-top: -100px !important;
  opacity: 0;
}

.flip-enter-active {
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out
}

.flip-leave-active {
  -webkit-transition: all .15s ease-in;
  transition: all .15s ease-in
}

.flip-enter,.flip-leave-to {
  -webkit-transform: scaleX(0);
  transform: scaleX(0)
}

@media only screen and (max-width: 767px) {
  [class*="computer only"]:not(.mobile),[class*="large screen only"]:not(.mobile),[class*="tablet only"]:not(.mobile),[class*="widescreen only"]:not(.mobile) {
      display:none!important
  }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
  [class*="computer only"]:not(.tablet),[class*="large screen only"]:not(.tablet),[class*="mobile only"]:not(.tablet),[class*="widescreen only"]:not(.tablet) {
      display:none!important
  }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
  [class*="large screen only"]:not(.computer),[class*="mobile only"]:not(.computer),[class*="tablet only"]:not(.computer),[class*="widescreen only"]:not(.computer) {
      display:none!important
  }
}

@media only screen and (min-width: 1200px) and (max-width:1919px) {
  [class*="mobile only"]:not(.computer),[class*="tablet only"]:not(.computer),[class*="widescreen only"]:not(.mobile) {
      display:none!important
  }
}

@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not(.computer),[class*="tablet only"]:not(.computer) {
      display:none!important
  }
}

.divider-or {
  text-align: center;
  color: @darkerGrey;
  font-size: 12px;

  span {
    position: relative;
    top: -3px;
    background-color: @darkerGrey;
    height: 1px;
    width: 15px;
    display: inline-block;
    margin: 0 2px;
  }
}

/************************************************
  Global support for responsive hiding elements
*************************************************/

/* mobile only */
@media only screen and (max-width: @largestMobileScreen) {
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* tablet only */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  [class*="mobile only"]:not(.tablet),
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet) {
    display: none !important;
  }
}

/* computer only */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer) {
    display: none !important;
  }
}

/* large screen only */
@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important;
  }
}

/* widescreen only */
@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer) {
    display: none !important;
  }
}
