@import "@/styles/scss/_variables.scss";




























.partnerView,
.partnerIndexView {
  &__hero {
    background-color: $cl-sail-lighten-12;
  }

  .hero {
    &__shape {
      &.-logo {
        fill: $cl-sail-lighten-8;
      }
      &.-bottom {
        fill: $cl-supernova-lighten-16;
      }
      &.-top {
        fill: $cl-radical-red-lighten-16;
      }
    }
  }
}
