@import "@/styles/scss/_variables.scss";





































































.onboarding-auth {
  max-width: 330px;
  margin: 0 auto;
  @media screen and (min-width: $desktop) {
    margin: 0;
  }

  > div {
    margin-bottom: 16px;
  }

  &__headline {
    margin-bottom: 32px;
  }

  &__hint {
    color: $cl-mako;
    opacity: .6;
    margin-bottom: 8px;
  }

  &__error {
    &.aj-message {
      margin-bottom: -15px;
    }
  }

  &__resetPassword {
    margin-top: 18px;
  }

  &__submit {
    margin-top: 20px;
  }

  .onboarding-auth__password {
    margin-bottom: 0;
  }
}
