@import "@/styles/scss/_variables.scss";
























































































.onboarding__stepControls {
  &.-single {
    justify-content: flex-end;
  }
}
