@import "@/styles/scss/_variables.scss";








































































































































































.jobOffersList__previewCard {
  .jobOffer__description {
    @media (min-width: $desktop) {
      height: calc(100vh - 350px);
      padding: 0 25px 0 20px;
      overflow: auto;
      margin-bottom: 0;
    }
  }
}
.jobOffer {
  &__detailsTable {
    margin-bottom: 15px;
  }

  &__detailRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid $cl-mako-alpha-15;
    }
  }

  &__detailHeader {
    text-transform: uppercase;
  }

  &__description {
    margin: 10px 0 0;
    padding: 0 16px;

    @media (min-width: $desktop) {
      padding: 0;
    }
  }
}

.description__headline {
  margin-bottom: 14px;
}

.separator {
  width: 100%;
  margin: 30px 0;
}
