@import "@/styles/scss/_variables.scss";







































.profile-container {
  position: relative;
}
.profileElement__language {
  span {
    font-weight: 300;
  }
}
