@import "@/styles/scss/_variables.scss";
































.separated.segment.beige {
  background: $cl-dawn-pink-lighten-8;
}

.ui.review.cards {
  font-size: 0.8em;
}

.with-rating .ui.header.alternative {
  border-bottom: none;
}

.ui.alternative.header {
  line-height: 1.5;
  padding: 0;
}
