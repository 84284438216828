/*******************************
        User Overrides
*******************************/
.item.right {
  .ui.inline.dropdown {
    .menu {
      left: auto;
      right: 0;
    }

    .language-selector {
      > .menu {
        overflow: auto;
        max-height: 60vh;

        &:after {
          display: none;
        }
      }
    }
  }
}

/* Selected Item Hover */
.ui.selection .menu .selected.item:hover {
  background: darken(@darkWhite, 10%);
}

.ui.dropdown {
  &.selection {
    border: none;
    min-height: initial;
    max-height: 48px;

    & > .search.icon {
      margin: 0;
      top: 0.6em;
      font-size: 0.8em;
      fill: #3A3F48;
    }

    & > .text {
      &.default  {
        font-weight: 300;
      }

      .flag {
        display: none;
        position: relative;
        top: 0px;
        margin-left: 31px;
      }
    }

    &.loading {
      & > .search.icon {
        background-color: transparent !important;
        top: 0.2em;
      }
    }
    &.active {
      background: #fff;
      box-shadow: 0 2px 18px rgba(58,63,72,.2);
      color: red;
      &:hover {
        box-shadow: 0 2px 18px rgba(58,63,72,.2);
        .menu {
          box-shadow: 0 2px 18px rgba(58,63,72,.15);
        }
      }
      .menu {
        border: 0;
        box-shadow: 0 2px 18px rgba(58,63,72,.15);
      }
    }

    .menu {
      width: 100%;
      min-width: 100%;
      margin: 0;
      box-shadow: 0 2px 18px rgba(58,63,72,.15);
    }
  }

  .menu > .header {
    font-weight: normal;
  }
}

.ui.search.selection.dropdown {
  & > input.search {
    padding: 13px 51px 16px 50px;

    @media (max-width: 480px) {
      padding: 7px 51px 3px 43px;
    }
  }
}

.ui.search.dropdown {
  & > input.search {
    font-family: @fontName;
  }
}

.headers {
  .ui.dropdown .menu {
    left: -97px;
    width: 150px;
  }

  .ui.menu .dropdown.item .menu {
    margin-top: 8px;
    border-radius: 10px;
  }

  .ui.pointing.dropdown>.menu:after {
    left: 80%;
    width: 13px;
    height: 13px;
    top: -6px;
  }

  .ui.inline.dropdown {
    display: flex;
  }
}

.ui.dropdown .menu > .divider {
  min-height: 1px;
  margin: 0;
  width: 100%;
  &::before,
  &::after {
    display: none;
  }
}
