.ui.modal.mobile-fit {
  min-height: 250px;
}
.ui.modal.mobile-fit form {
  padding: 40px;
}
.ui.modal.mobile-fit form button {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .ui.modal.mobile-fit .menu.transition {
    max-height: 6rem;
  }
}
