@import "@/styles/scss/_variables.scss";

















.termsStep {
  max-width: 650px;
}
