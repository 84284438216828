.vod {
  position: relative;
  cursor: pointer;
  text-align: center;

  & > .vod-svg {
    position: absolute;
    top: ~"calc(50% - 30px)";
    left: ~"calc(50% - 40px)";

    & > .vod-path {
      fill: #3E312C;
    }
  }

  &:hover {
    & > .vod-svg {
      & > .vod-path {
        fill: #f00;
      }
    }
  }
}
