@import "@/styles/scss/_variables.scss";






















































































.jobOffer {
  .-notAvailable {
    .hero {
      &__content {
        padding-bottom: 15px;
      }

      &__notAvailableMessage {
        margin: 15px 0;
      }

      &__notAvailableAction {
        &.aj-button {
          display: inline-block;
          padding: 5px 0 0 0;
          color: $cl-white;
          font-size: 14px;
          line-height: 1.4em;
          text-align: left;

          @media (min-width: $desktop) {
            padding: 0 0 0 5px;
          }
        }
      }
    }
  }
}
