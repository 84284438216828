@import "@/styles/scss/_variables.scss";





































































































































.onboarding__step {
  &Text {
    margin-top: 10px;
    color: $cl-mako-lighten-48;
  }

  &Content {
    padding-bottom: 75px;
  }
}

.birthDateStep {
  max-width: 475px;
}
