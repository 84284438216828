.ui.header {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1em;
  max-height: 2em;
  display: block;
}
.jobOfferTile.myDeskWidth {
  width: 275px;
}
.jobOfferTile__footer {
  font-size: 12px;
  display: flex;
  margin-top: 10px;
}
.jobOfferTile__footer .half {
  flex: 1 0 50%;
}
.jobOfferTile__category {
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: hidden;
}
.ui.equal.width.center.aligned.padded.stretched {
  padding: 0 8px;
}
