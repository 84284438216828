@import "@/styles/scss/_variables.scss";
















































.moreJobsView,
.moreJobsOfferView {
  &__hero {
    background-color: $cl-supernova-lighten-16;
  }
  &__heroSearchResult {
    margin-right: 7px;
    color: $cl-radical-red-darken-12;
  }

  .hero {
    &__shape {
      &.-logo {
        fill: $cl-supernova-lighten-24;
      }
      &.-bottom {
        fill: $cl-radical-red-lighten-8;
      }
      &.-top {
        fill: $cl-sail-darken-12;
      }
    }

    &__headline {
      margin-top: 20px;
    }

    &__content {
      position: relative;
      padding: 65px 15px 20px;

      @media (min-width: $desktop-small) {
        padding: 100px 16px 32px;
      }
      @media (min-width: $desktop-small) {
        max-width: $desktop-layout-width;
        margin: 0 auto;
      }
      @media (min-width: $desktop) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: $desktop-big) {
        max-width: $desktop-big-layout-width;
      }
    }
  }
}
