.uploader__link {
  margin-left: 10px;
  display: inline-block;
  font-size: 0.85714286rem;
}
.uploader .image-wrapper {
  border-radius: 50%;
  box-shadow: #4a4a4a 0 2px 4px;
  display: inline-block;
  margin: 0 auto;
}
.uploader .image-wrapper .image {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  padding: 0;
  margin: auto;
  box-shadow: inset #f7a504 0 0 5px 2px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.uploader .image-wrapper .image.error {
  box-shadow: inset #d51e35 0 0 6px 5px;
}
.uploader .menu {
  margin-top: 10px;
}
