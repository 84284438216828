@import "@/styles/scss/_variables.scss";













.newLabel {
  display: inline-block;
  padding: 1px 5px;
  transform: skew(-10deg);
  background-color: $cl-radical-red;
  color: $cl-white;

  .aj-text {
    display: inline-block;
    transform: skew(10deg);
  }
}
