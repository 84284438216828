@import "@/styles/scss/_variables.scss";











































.offer-ugc {
  position: relative;
}

.user-earnings {
  display: block;
  font-weight: bold;
  font-size: 1.4rem;
}

.number-of-users {
  clear: both;
  font-size: 0.9rem;
  opacity: 0.6;
}

.separator {
  width: 100%;
  margin: 30px 0;
}

.wrapper {
  clear: both;
}

.key {
  line-height: 22px;
  font-size: 1rem;
}
