<template>
  <div class="partner-selector">
    <dropdown-menu
      class="fluid"
      :class="[variant, size, partnerListLoading]"
      :list="partnerList"
      name="partner"
      :label="translate('search.company')"
      :list-warning="listWarning"
      type="search"
      :preselect="preselectPartner()"
      @search="searchPartner($event)"
      @change="changePartner($event[0])"
    />
  </div>
</template>

<style>
  .partner-selector .ui.selection.dropdown {
    padding: 0;
  }
</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { isMobile } from '@/js/helpers/breakpoints';
import DropdownMenu from '@/js/components/ui/dropdown-menu';
import { translate } from '../../helpers/i18n';

export default {
  name: 'JobOfferSelector',

  components: {
    DropdownMenu,
  },

  props: {
    variant: {
      type: String,
    },

    cityId: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      listWarning: null,
      partnerList: [],
      partnerId: 0,
      size: 'large',
    };
  },

  computed: {
    ...mapState('jobOffers', ['jobOffers', 'isLoading', 'isMyOffersLoaded']),
    ...mapGetters('jobOffers', ['notMyJobOffers']),

    partnerListLoading () {
      return this.isLoading ? 'loading' : '';
    },
  },

  watch: {
    async cityId (id) {
      this.partnerList = [];
      this.partnerId = 0;
      await Promise.all([
        this.getOffers({ city_id: id }),
        !this.isMyOffersLoaded ? this.getMyJobOffers() : null,
      ]);
    },

    notMyJobOffers (offers) {
      this.partnerList = offers.map(offer => offer.partner);
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.nothingElseMattersButSize();
    });
  },

  methods: {
    ...mapActions('jobOffers', ['getOffers', 'getMyJobOffers']),

    nothingElseMattersButSize () {
      const overTabletBreakpoint = !isMobile;

      this.size = overTabletBreakpoint ? 'huge' : 'large';
    },

    searchPartner (searchString) {
      const offersFound = this.notMyJobOffers
        .filter(item => item.partner.name.toLowerCase().indexOf(searchString.toLowerCase()) === 0);
      this.listWarning = null;

      if (offersFound.length === 0) {
        this.listWarning = translate('search.company.notFound');
        this.partnerList = [];
      } else {
        this.partnerList = offersFound.map(offer => offer.partner);
      }
    },

    changePartner (id) {
      if (!id) {
        return;
      }

      this.partnerId = id;
    },

    preselectPartner () {
      if (!this.partnerId || !this.partnerList.length) {
        return undefined;
      }

      const foundPartner = this.partnerList.find(partner => partner.id === this.partnerId);
      const foundOffer = this.notMyJobOffers.find(offer => offer.partner.id === this.partnerId);

      if (!foundPartner || !foundOffer) {
        return undefined;
      }

      // Emit job offer id
      this.$emit('offer-found', foundOffer);

      // Select partner in the dropdown's list
      return foundPartner;
    },
  },
};
</script>
