@import "@/styles/scss/_variables.scss";



















































.noReviews {
  &__button {
    margin: 15px 0;
  }
}
