@import "@/styles/scss/_variables.scss";









.topBar {
  position: fixed;
  z-index: 900;
  top: 0;
  width: 100%;
}
