@import "@/styles/scss/_variables.scss";



















































































































.onboardingCta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 23px 20px 13px 23px;
  margin: 14px 0 35px;
  background-color: $cl-supernova-lighten-16;
  overflow: hidden;

  @media screen and (min-width: $tablet) {
    margin-top: 19px;
    flex-direction: row;
    align-items: center;
  }
  @media screen and (min-width: $desktop) {
    padding: 40px 32px;
  }

  &__searchIcon {
    position: absolute;
    width: 45px;
    fill: $cl-supernova;

    @media screen and (min-width: $tablet) {
      position: relative;
      width: 60px;
      margin: 0 30px 0 0;
    }

    @media screen and (min-width: $desktop) {
      width: 94px;
    }
  }

  &__content {
    position: relative;
    padding-left: 58px;
    color: $cl-mako;
    @media screen and (min-width: $tablet) {
      padding-right: 30px;
      padding-left: 0;
    }
  }

  &__headline {
    margin-bottom: 8px;
  }

  &__description {
    &.aj-text {
      @media screen and (min-width: $tablet) {
        font-size: 14px;
        line-height: 24px;
      }

      @media screen and (min-width: $desktop) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__link {
    position: relative;
    margin: 16px 0 0 50px;
    color: $cl-radical-red-lighten-8;
    text-align: left;

    &:hover {
      svg {
        left: 9px;
      }
    }

    @media screen and (min-width: $tablet) {
      position: relative;
      flex: 0 0 auto;
      margin: 0 0 0 auto;
      align-self: center;
    }

    .aj-text {
      @media screen and (min-width: $tablet) {
        font-size: 16px;
        font-weight: 600;
      }
      @media screen and (min-width: $desktop) {
        font-size: 20px;
      }
    }

    svg {
      position: relative;
      top: 2px;
      left: 6px;
      transition: left .2s ease-in-out;

      @media screen and (min-width: $desktop) {
        top: 0;
      }
    }

    &.aj-button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
