@import "@/styles/scss/_variables.scss";











.componentLoader {
  min-height: 100vh;
  position: relative;
}
