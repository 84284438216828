@import "@/styles/scss/_variables.scss";





























.aj-button.back-button {
  $button-size: 24px;

  color: $cl-mako-lighten-32;
  height: $button-size;
  width: $button-size;
  svg {
    height: $button-size;
    width: $button-size;
  }

  &:hover {
    color: $cl-mako-lighten-16;
    text-decoration: none;
  }
}
