@import "@/styles/scss/_variables.scss";








.aj-loader {
  z-index: 1;
}
