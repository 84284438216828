@import "@/styles/scss/_variables.scss";

































































.aj-loader {
  min-height: calc(100vh - 170px);
}
