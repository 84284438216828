@import "@/styles/scss/_variables.scss";













































.image.content.ui.grid {
  min-height: 520px;
  margin: 0;
  padding: 25px;

  .column-left {
    text-align: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    @media (min-width: $tablet) {
      display: flex;
    }

    .logo {
      .ui.image {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .column-right {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $cl-mako-alpha-30;
    @media screen and (max-width: $tablet) {
      border-left: none;
    }
    align-content: center;

    .even-z.auth.container {
      max-width: 100%;
      margin: 0 auto 0 auto;
    }
  }
}

.ui.dimmer {
  @media screen and (max-width: $tablet) {
    padding: 0;
  }
}

.ui.modal {
  .close {
    top: 10px;
    right: 10px;
  }

  &.mobile-fit.inverted.active {
    margin-top: 50px;
    height: auto;
    display: flex;
    width: 650px;
    align-items: stretch;

    @media screen and (max-width: $tablet) {
      width: 100vw;
      height: 100%;

      .two.column.grid {
        flex-direction: row;
        justify-content: center;

        &.reversed {
          flex-direction: column-reverse;
          flex-wrap: nowrap;

          .column-right {
            flex-shrink: 0;
          }
        }
      }

      .column-right {
        width: 100%;
        padding: 0;
        flex-grow: 1;
      }
    }
  }

  .row {
    .column {
      &.header {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      text-align: center;
    }
  }

  @media screen and (min-width: $tablet) {
    .row {
      display: flex;
      align-items: center;
      margin-top: 1em;

      .column {
        flex: 0 0 50%;

        .header {
          margin-bottom: 0;
          flex: 0 0 66%;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .ui.modal > .content > .description {
    padding-top: 2rem;
  }
}
