









.progress-wrapper.signup-length {
  width: 80px;
  height: 14px;
  background: @teal;
  float: left;
  margin: 5px 12px 5px 0;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
