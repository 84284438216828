





























































.avatar-holder {
  display: inline-block;
  height: 140px;

  .menu {
    margin-top: 10px;

    .small.link {
      font-size: @relativeSmall;
    }
  }
}

.ui.stackable.grid {
  margin: 0;
}

.ui.grid > .row > .column {
  padding: 20px;

  &.four.wide {
    display: flex;
    flex-direction: column;
    padding-top: 30px !important;
    padding-right: 10px;
    padding-bottom: 10px !important;
    padding-left: 40px;

    img {
      margin: 0 auto;
    }

    > p {
      font-size: 14px;
      opacity: 0.5;
      padding: 15px 0;
    }
  }

  &.seven.wide {
    box-shadow: none;
  }
}

.header:first-child {
  margin-top: 15px;
}

.fixed-height {
  min-height: 80px;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
