@import "@/styles/scss/_variables.scss";


































































.aj-modal {
  &.-filters {
    position: relative;

    &.-scrollable {
      .aj-modal__content {
        height: calc(100vh - 170px);
        padding-bottom: 150px;
      }
    }

    .aj-pill-group__item {
      margin: 0 8px 8px 0;
    }

    .aj-modal {
      &__buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        box-shadow: 0 6px 9px 0 $cl-mako;

        @media screen and (min-width: $desktop) {
          box-shadow: none;
        }

        .aj-button {
          margin: 0;
          width: auto;

          @media screen and (min-width: $desktop) {
            width: 100%;
          }
        }
      }
      &__container {
        @media screen and (min-width: $desktop) {
          max-width: $desktop;
        }
      }
    }
  }
}

.filters {
  &__section {
    margin-bottom: 15px;
  }

  &__sectionHeadline {
    margin-bottom: 10px;
  }
}
