



































































































@import "~semantic-ui-less/themes/default/globals/site.variables";
.tabs {
  position: relative;

  .tabs-tab {
    @media (max-width: @tabletBreakpoint) {
      width: 25%;
      text-align: center;
    }
  }
}

@media (max-width: @largestMobileScreen) {
  .tabs-items {
    display: inline-block;
    margin: 0;
    min-width: 100%;
  }

  .tabs-panels {
    margin-top: 30px;
  }
}

.tabs-tab.with-dot {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 13px;
    top: 13px;
    height: 13px;
    width: 13px;
    border-radius: 13px;
    background-color: @orange;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
