@import "@/styles/scss/_variables.scss";





























































.onboarding {
  .aj-steps__progress {
    .onboardingMenu__step {
      margin-bottom: 16px;
    }
  }
}

.onboardingMenu {
  margin-top: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: $cl-supernova-lighten-16;

  &__steps {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__step {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 38px 8px 8px;
    border-radius: 8px;
    transition: background .25s ease-in-out;

    &.-completed {
      .onboardingMenu__stepIndicator {
        background-color: $cl-sail-darken-12;
      }
    }

    &.-active {
      background-color: $cl-supernova-lighten-32;
    }

    &:hover {
      background: $cl-supernova-lighten-8;
      cursor: pointer;
    }
  }

  &__stepIndicator {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    background-color: $cl-supernova-lighten-32;
    border-radius: 50%;

    svg {
      position: absolute;
      fill: $cl-white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__stepName {
    color: $cl-mako-lighten-16;
  }

  &__stepAction {
    position: absolute;
    right: 8px;

    p {
      color: $cl-radical-red-lighten-8;
    }
  }
}
