.ui.middle.aligned {
    &.center.aligned {
        height: 220px;
        align-items: center;
        @media only screen and (max-width: @largestMobileScreen) {
            height: 140px;
        }
    }
    &.two.column.grid{
        margin: 0;

        @media only screen and (max-width: @largestMobileScreen) {
          padding-bottom: 0;
          padding-top: 0;
        }
        > .column {
          padding: 0;
        }
      }
}
