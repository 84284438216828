.separator {
  padding: 0;
  margin: 16px auto 30px;
  height: 1px;
  width: 150px;
  background-color: #878F9E;

  &.hidden {
    background-color: transparent;
  }
}
