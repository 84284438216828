@import "@/styles/scss/_variables.scss";


























































.categories {
  margin: 32px 0;
  @media (min-width: $tablet) {
    margin: 60px 0 40px;
  }

  &__header {
    text-align: left;
    @media (min-width: $tablet) {
      text-align: center;
    }
  }

  &__cards {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1400px) {
      flex-wrap: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    @media (max-width: $tablet - 1) {
      margin: 25px -1em 25px 0;
    }

    @media (min-width: 1400px) {
      justify-content: center;
    }

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-track {
      height: 0;
      width: 0;
    }
  }

  &__card {
    height: 250px;
    flex: 0 0 calc(16.6% - 25px);
    background-color: $cl-dawn-pink;
    margin-right: 25px;
    padding: 18px 24px;
    border-radius: 17px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 1400px) {
      flex: 0 0 200px;
    }

    &__header {
      color: $cl-mako;
      font-weight: 900;
      font-size: 1.5rem;
      margin-bottom: 10px;
      margin-top: 0;

      @media (max-width: 1400px) {
        font-size: 16px;
      }
    }

    &__subheader {
      margin: 0;
      font-size: 1rem;
      font-weight: normal;
      color: $cl-mako-lighten-32;
      width: 116px;
    }

    &__image {
      position: absolute;
      bottom: 40px;
      right: 0;
    }

    &__link {
      position: absolute;
      bottom: 18px;
      left: 0;
      right: 0;
      text-align: center;

      a {
        display: inline-block;
      }
    }
  }

  &__check-all {
    text-align: center;

    a {
      display: inline-block;
    }
  }
}

.loader {
  min-height: 250px;
}
