@import "@/styles/scss/_variables.scss";














.moreJobsView__content {
  background-color: $cl-dawn-pink-lighten-8;
}
