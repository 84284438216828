@import "@/styles/scss/_variables.scss";


















































































































































.verify__tokenInput .aj-input {
  width: auto;

  &__textField {
    width: 64px;
    height: 56px;

    @media (min-width: $tablet) {
      width: 72px;
      height: 64px;
    }

    input {
      max-width: 100%;
      font-size: 24px;
      text-align: center;
    }
  }
}
