@import "@/styles/scss/_variables.scss";












































































.moreJobsOfferView {
  &__expiredMessage {
    .aj-message {
      &__wrapper {
        @media (min-width: $tablet) {
          justify-content: left;
          align-items: flex-start;
        }
      }
      &__content {
        @media (min-width: $tablet) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &__message {
        margin-top: 10px;
      }
    }
  }
}
