@import "@/styles/scss/_variables.scss";
















































































.dataEdit {
  max-width: 500px;
  margin: 0 auto;
  &__cancel {
    margin-right: 10px;
  }

  &__buttons {
    margin-top: 25px;
    text-align: center;
  }

  .aj-input {
    margin-bottom: 15px;
  }

}

.message {
  display: none;
}

.error .message {
  display: block;
}
