.widget {
  max-width: 280px;
  width: 100%;
}
.widget.fluid {
  max-width: none;
}
.widget /deep/ .spaced.field {
  margin: 1.3em auto;
}
.widget /deep/ .header {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #4a4a4a;
  margin: 25px;
}
