@import "@/styles/scss/_variables.scss";



















































































.userDeskJobs {
  &__container {
    padding: 0 21px;
    width: 100%;
    max-width: 1200px;
  }

  &__headline {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  &__description {
    margin-bottom: 48px;

    @media (min-width: $tablet) {
      margin-bottom: 33px;
    }
  }

  &__loader {
    margin: 24px;
  }

  &__preferences {
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 48px;

    @media (min-width: $tablet) {
      margin-bottom: 33px;
    }
  }
}

.noOffers {
  margin-top: 60px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;

  @media (min-width: $tablet) {
    margin-top: 45px;
  }

  &__icon {
    stroke: $cl-mako-lighten-48;
    width: 30px;
    margin-bottom: 16px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 18px;

    @media (min-width: $tablet) {
      margin-top: 20px;
      margin-bottom: 35px;
      max-width: 550px;
    }
  }

  &__promotedOffers {
    width: 100%;
    margin-top: 50px;
  }
}

.offers-cards {
  display: block;
}

@media only screen and (min-width: $desktop-small) {
  .ui.container.segment.promoted {
    max-width: 60% !important;
  }
}

.ui.vertical.segment {
  background-image: url('/static/my-desk-header.jpg');

  .desktop.sized {
    padding-top: 20px;

    @media screen and (max-width: $tablet - 1) {
      height: 150px;
      padding: 0;
      margin-bottom: 0;
    }
  }

  .ui.center.aligned.white.header {
    margin-bottom: 0;
  }
}

.ui.center.aligned.no-match.header {
  padding: 1em;
}
