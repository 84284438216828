.ui.modal > .content > * {
  padding: 0 2.5rem !important;
}
.ui.modal h1,
.ui.modal h2 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}
.ui.modal h1 {
  margin-top: 40px;
}
.ui.modal h2 {
  font-size: 1.2rem;
  color: #9c9c9c;
  margin-bottom: 16px;
}
.ui.modal .label,
.ui.modal .review {
  color: #9c9c9c;
}
.ui.modal .label {
  font-size: 1.1rem;
}
.ui.modal .review {
  font-style: italic;
  font-size: 0.9rem;
}
.ui.modal button {
  margin: 25px 0;
}
