.review {
  margin-bottom: 1em;
}
.review .rating-label {
  color: #9b9b9b;
}
.review .description {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9b9b9b;
  font-size: 0.9em;
  font-style: italic;
}
.text,
.text-rejected {
  font-size: 1rem;
  line-height: 26px;
}
.text-rejected {
  color: #d51e35;
}
.delete.link {
  padding: 15px 0 5px;
}
.ui.inverted.active.dimmer {
  padding: 2em;
}
.review-actions button:first-of-type {
  margin-bottom: 15px;
}
