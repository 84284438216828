@import "@/styles/scss/_variables.scss";

















.aj-passwordStrength {
  margin-top: 15px;
}
