@import "@/styles/scss/_variables.scss";













































































.signUp {
  &__email {
    margin-bottom: 15px;
  }
}
.ui .message {
  display: none;
}

.ui.error .message {
  display: block;
}

.ui.success .success.message:not(:empty) {
  display: block;
}
