@import "@/styles/scss/_variables.scss";





















.cityHint {
  position: relative;
  z-index: 5;
  padding: 0;
  @media screen and (min-width: $desktop-small) {
    margin-top: 20px;
  }
  li {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    color: $cl-radical-red;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      color: $cl-radical-red-darken-12;
    }
  }
}
