@import "@/styles/scss/_variables.scss";




















































































.columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  margin: 20px 0;

  @media (min-width: $desktop-small) {
    margin: 60px 0;
  }

  .column {
    width: 100%;

    @media (max-width: $desktop-small - 1) {
      &__left {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    @media (min-width: $desktop-small) {
      &__left {
        margin-right: 60px;
        width: 25%;
      }

      &__right {
        width: calc(75% - 60px);
      }
    }
  }
}
