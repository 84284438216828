.ui.desktop.sized.fluid.container {
  padding-top: 30px;
  padding-bottom: 0;
}
.masthead h1.ui.header {
  font-size: 1.6rem;
}
@media only screen and (min-width: 769px) {
  .masthead h1.ui.header {
    font-size: 2.4rem;
  }
}
.ui.container.fluid.dark {
  padding-top: 0;
}
.ui.container.text.narrow {
  margin-top: 1em;
}
.category-rating {
  display: inline-flex;
  font-size: 1rem;
  line-height: 1;
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid #9c9c9c;
}
