.ui.grid.card {
  width: 100%;
}
h1.header {
  font-weight: normal;
  font-size: 2.2em;
  margin-bottom: 0;
}
.texts {
  height: 100%;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .texts {
    margin-top: 0;
  }
}
.texts > * {
  padding-left: 20px;
}
.texts-header {
  margin-top: 40px;
  position: relative;
}
.texts-header > .subheader {
  opacity: 0.4;
  font-size: 0.9em;
}
@media only screen and (max-width: 767px) {
  .texts-header {
    margin-top: 1em;
  }
}
@media (max-width: 1199px) {
  .texts-header .ui.header {
    font-size: 1.2em;
  }
}
.texts-links {
  font-size: 0.9em;
  line-height: 1.4em;
  opacity: 0.8;
}
@media only screen and (min-width: 767px) {
  .texts-links {
    position: absolute;
  }
}
.texts-links > .link-wrapper {
  margin-top: 1.2em;
}
.texts-links > .link-wrapper:last-child {
  margin-bottom: 10px;
}
.texts-links .link {
  display: block;
  font-weight: bold;
}
.card.background.picture {
  background-color: #485e7a;
  background-repeat: no-repeat;
  background-size: cover;
}
