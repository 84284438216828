.drawer {
  position: fixed;
  left: 0;
  z-index: 101;
}
.drawer .header {
  box-shadow: 0 1px 3px 0 #e8e8e8;
}
.drawer .header .title {
  padding-left: 56px;
}
