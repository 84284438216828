.blogline {
  margin: (@absoluteMassive * 1.5) auto;
  width: 35%;
  background-color: @special;
  height: 1px;
}

.blog {
  figure {
    margin: @relativeBig auto;

    figcaption {
      margin: 5px auto;
      font-size: @relativeTiny;
      font-style: italic;
    }
  }

  img {
    max-width: 100%;
  }

  .figure {
    margin: 0;
    position: relative;
    padding-bottom: 60.54%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
