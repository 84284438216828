@import "@/styles/scss/_variables.scss";































.aj-modal {
  &.-cityRequest {
    .aj-modal {
      &__title,
      &__content {
        text-align: center;
      }
      &__buttons {
        @media screen and (min-width: $mobile-big) {
          justify-content: space-around;
        }
      }
    }
  }
}
