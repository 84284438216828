


























.infographic.ui.grid {
  > .column:not(.row) {
    &[class*="no padding"] {
      padding-left: 0;
      padding-right: 0;
    }
    &.separator {
      margin: auto;
      margin-top: 80px;
      padding: 0;
    }
    .text {
      font-size: 20px;
    }
  }

  &.small {
    > .column:not(.row) {
      &.separator {
        margin-top: 58px;
      }
      .text {
        font-size: 16px;
      }
    }
  }

  &.small-mobile {
    > .column:not(.row) .text {
      @media all and (max-width: @largestMobileScreen) {
        font-size: 16px;
      }
    }
  }

  &.emphasis {
    .text {
      font-weight: bold;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
