@import "@/styles/scss/_variables.scss";




































.ui.secondary.pointing.menu {
  .item {
    margin-bottom: 0;
    height: 100%;
    margin-right: 20px;
    color: $cl-white;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $cl-radical-red;
    }

    &.active {
      border-color: $cl-radical-red;

      &:hover {
        border-color: $cl-radical-red;
      }
    }
  }

  .newLabel {
    margin-left: 10px;
  }
}
