














































.categories-carousel {
  min-height: 273px;
  width: 100%;

  @media (max-width: 1400px) {
    min-height: 300px;
  }

  & > .item {
    display: flex;
    justify-content: center;
  }
}

.ui.doubling.stackable.cards {
  width: 100%;
  padding: 5px;
  justify-content: center;
}

.ui.four.cards>.card {
  overflow: hidden;
}

.carousel-nav {
  &.carousel-nav-prev {
    left: -25px;

    @media only screen and (min-width: @largestMobileScreen) {
      left: 0;
    }
  }

  &.carousel-nav-next {
    right: -25px;

    @media only screen and (min-width: @largestMobileScreen) {
      right: 0;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
