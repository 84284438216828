



















.language-selector {
  height: 19px;
  margin-left: 16px;
  padding-left: 0.85em;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #232d3a;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
