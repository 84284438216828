.ui.container.user-desk-container {
  max-width: 1200px !important;
  position: relative;
}
h1.white.header {
  padding-bottom: 1em;
  font-weight: normal;
}
@media (min-width: 992px) {
  h1.white.header {
    margin-top: 2em;
  }
}
.desktop.sized.fluid {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-width: 1200px !important;
}
@media (max-width: 991px) {
  .desktop.sized.fluid {
    margin-top: 2em;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .desktop.sized.fluid {
    margin-top: 4em;
  }
}
