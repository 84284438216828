.sidebar-nav {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .sidebar-nav {
    border-radius: 8px;
    background: #fff;
  }
}
.sidebar-nav li {
  list-style: none;
  padding: 8px;
  color: #9c9c9c;
  display: inline-block;
  cursor: pointer;
}
.sidebar-nav li:hover {
  color: #ff3d4d;
}
@media (max-width: 991px) {
  .sidebar-nav li:first-of-type {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .sidebar-nav li {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    position: relative;
    color: #000;
  }
  .sidebar-nav li:last-of-type {
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .sidebar-nav li.active {
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    border-left-color: #ff3d4d;
    border-left-style: solid;
    color: #ff3d4d;
    animation: animateBorder 0.5s;
    animation-fill-mode: both;
  }
  @keyframes animateBorder {
    0% {
      border-left-width: 0;
    }
    100% {
      border-left-width: 4px;
    }
  }
}
.sidebar-nav li.active > .icon {
  color: #ff3d4d;
  opacity: 1;
}
.sidebar-nav .icon {
  height: auto;
  margin-right: 12px;
}
