@import "@/styles/scss/_variables.scss";






















.jobOffer {
  &__tabs {
    margin-top: 10px;
    padding-bottom: 30px;
    @media (min-width: $desktop) {
      margin-top: 20px;
    }
  }

  &__reviews {
    padding: 0 16px;
    @media (min-width: $desktop) {
      padding: 0;
    }
  }
}

.partner {
  &__reviewsTab {
    max-width: 735px;
  }
  &__descriptionTab {
    padding: 0 16px;
  }
}
