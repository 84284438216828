@import "@/styles/scss/_variables.scss";
































































.tabItem__content {
  padding: 0 16px;
}
