@import "@/styles/scss/_variables.scss";




























.aj-modal {
  &.-directApply {
    &.-scrollable {
      .aj-modal__content {
        height: 100%;
      }
    }

    .aj-modal__container {
      max-width: 400px;
    }
  }
}

.directApply {
  &__header {
    margin-top: 10px;
  }
  &__form {
    margin-top: 15px;
  }
  &__formInput {
    margin-top: 10px;
  }
  &__submitButton {
    margin-top: 20px;
  }
}
