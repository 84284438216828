





















.ui.container.user-desk-container {
  max-width: 1200px !important;
  position: relative;
}

h1.white.header {
  padding-bottom: 1em;
  font-weight: normal;

  @media (min-width: @computerBreakpoint) {
    margin-top: 2em;
  }
}

.desktop.sized.fluid {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-width: 1200px !important;

  @media (max-width: @largestTabletScreen) {
    margin-top: 2em;
  }

  @media screen and (max-width: @largestTabletScreen) and (min-width: @tabletBreakpoint) {
    margin-top: 4em;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
