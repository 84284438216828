@import "@/styles/scss/_variables.scss";
































.aj-modal {
  &.-deleteProfileConfirm {
    .aj-modal {
      &__content {
        text-align: center;
      }
      &__question {
        background-color: #f7a504;
        color: $cl-white;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        padding: 1.5em;
      }
      &__buttons {
        @media screen and (min-width: $mobile-big) {
          justify-content: space-around;
        }
      }
    }
  }
}
