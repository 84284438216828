.slot-handler {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.scroll {
  position: relative;
  display: flex;
}
.scroll .scroll-nav {
  z-index: 1;
  padding: 0 .5em;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  position: absolute;
}
.scroll .scroll-nav.scroll-left {
  left: 0;
  background: linear-gradient(to right, #ffffff 0, #ffffff 0.8em, rgba(255, 255, 255, 0) 100%);
}
.scroll .scroll-nav.scroll-right {
  right: 0;
  background: linear-gradient(to left, #ffffff 0, #ffffff 0.8em, rgba(255, 255, 255, 0) 100%);
}
