










.auth-button {
  display: flex;
  align-items: center;
  text-align: center;

  &.hidden {
    display: none;
  }

  @media only screen and (min-width: @largestMobileScreen) {
    .user-menu {
      transition: all 800ms ease;
    }
  }
}
.headers {
  .auth-button {
    @media only screen and (min-width: @largestMobileScreen) {
      margin-left: 30px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
