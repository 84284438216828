@import "@/styles/scss/_variables.scss";

























.page {
  &__container {
    padding: 60px 15px 30px;
    @media (min-width: $desktop) {
      padding: 100px 0 50px;
    }
  }

  &__content {
    @media (min-width: $desktop) {
      max-width: 1280px;
      margin: 0 auto;
    }

    ul {
      padding-left: 20px;
      list-style: disc;
    }
  }

  &__headline {
    margin-bottom: 15px;
    @media (min-width: $desktop) {
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    margin-top: 20px;
  }
}
