@import "@/styles/scss/_variables.scss";









































.partners {
  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 20px -15px 0;
    min-height: 400px;
  }

  &__empty {
    margin: 50px 0;
  }
}
