



















.filters {
  .ui.label.filter {
    margin: 4px;
  }
}

.filters.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .ui.label.filter {
    text-align: center !important;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
