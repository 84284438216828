@import "@/styles/scss/_variables.scss";


























.resetPassword__headline {
  @media (min-width: $tablet) {
    text-align: center;
  }
}

.aj-modal {
  &.-resetPassword {
    .aj-modal {
      &__container {
        max-width: 560px;
        padding-top: 75px;
        @media (min-width: $tablet) {
          padding-top: 67px;
          border-radius: 24px;
        }
        padding-bottom: 80px;
      }

      &__content {
        max-width: 400px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
      }

      &__close {
        top: 16px;
        right: 16px;
        @media (min-width: $tablet) {
          top: 20px;
          right: 24px;
        }
      }
    }
  }
}
