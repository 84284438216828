.add-item {
  display: block;
  font-size: 1.1rem;
  padding-top: 10px;
  cursor: pointer;
  color: #1F7DB3;
}
.add-item:hover {
  text-decoration: underline;
}
.item-added {
  display: block;
  margin: 4px 0;
  font-size: 1.1rem;
}
