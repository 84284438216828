@import "@/styles/scss/_variables.scss";



































.partnerIndexView {
  .hero {
    &__headline {
      margin-top: 20px;
    }

    &__content {
      position: relative;
      padding: 65px 15px 20px;

      @media (min-width: $desktop-small) {
        max-width: $desktop-layout-width;
        margin: 0 auto;
        padding: 100px 16px 32px;
      }
      @media (min-width: $desktop) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: $desktop-big) {
        max-width: $desktop-big-layout-width;
      }
    }
  }
}
