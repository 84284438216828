



















.tabs {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: @largestMobileScreen) {
    margin-top: 25px;
  }

  .tab {
    cursor: pointer;
    flex: 1 0 50%;
    font-size: 14px;
    text-align: center;
    color: @darkGrey;

    &.active {
      color: @lightBlack;
      border-bottom: 1px solid @lightBlack;
    }

    &:hover {
      color: @lightBlack;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
