















.widget {
  max-width: 280px;
  width: 100%;

  &.fluid {
    max-width: none;
  }

  /deep/ .spaced.field {
    margin: 1.3em auto;
  }

  /deep/ .header {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: @lightBlack;
    margin: 25px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
