@import "@/styles/scss/_variables.scss";





































































.signIn {
  &__email {
    margin-bottom: 15px;
  }
  &__resetPassword {
    margin: 16px 0;
  }
}

.ui .message {
  display: none;
}

.ui.error .message {
  display: block;
}
