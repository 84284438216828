.main-container {
  margin: 1.5em 0;
}
.header-modal-level {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.level {
  margin: 1.5em 0;
}
