<template>
  <container-wrapper>
    <h1 class="ui header">
      {{ translate('impressum.main.headline') }}
    </h1>
    <br>
    <section>
      <h2 class="ui header">
        {{ translate('impressum.section.basicInformation.headline') }}
      </h2>
      <p v-html="translate('impressum.section.basicInformation.content')" />
    </section>
    <section>
      <h2 class="ui header">
        {{ translate('impressum.section.board.headline') }}
      </h2>
      <p v-html="translate('impressum.section.board.content')" />
    </section>
    <section>
      <h2 class="ui header">
        {{ translate('impressum.section.legalInformation.headline') }}
      </h2>
      <p v-html="translate('impressum.section.legalInformation.content')" />
    </section>
    <section>
      <h2 class="ui header">
        {{ translate('impressum.section.disclaimer.headline') }}
      </h2>
      <p v-html="translate('impressum.section.disclaimer.content')" />
    </section>
  </container-wrapper>
</template>

<style scoped>
  section {
    margin-bottom: 1rem;
  }
</style>

<script>
import ContainerWrapper from '@/js/components/ui/container-wrapper';

export default {
  name: 'ImpressumContent',

  components: {
    ContainerWrapper,
  },
};
</script>
