

































































.red.close.icon {
  position: static;
  z-index: 0;
}
.modal-header {
  margin: 0;
}
.modal-info {
  color: @teal;
  margin-bottom: 2em;
}
.user-language {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  > i {
    cursor: pointer;
  }
}
.level {
  font-weight: 300;
}
.content-container {
  padding: 40px;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
