.wrapper {
  padding: 0 !important;
}
h1.header {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  padding: 20px 0 0;
}
.column,
.container {
  padding: 1.4em;
}
