

































.offer-tile-reviews {
  padding: 15px;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
