.wrapper {
  display: block;
  padding: 5px 0;
  cursor: pointer;
}
.wrapper .text-wrapper {
  display: flex;
  flex-direction: column;
}
.wrapper .iconic {
  width: 35px;
  margin: 4px 8px;
  float: left;
}
.wrapper .text {
  color: #4a4a4a;
  font-size: 1rem;
  opacity: 0.6;
  font-weight: lighter;
}
.wrapper .link {
  padding: 0;
  font-size: 1.1rem;
  color: #1F7DB3;
}
.wrapper .link:hover {
  text-decoration: underline;
}
