@import "@/styles/scss/_variables.scss";



























.app {
  &.-jobOffer {
    padding-bottom: 60px;
    @media (min-width: $tablet) {
      padding-bottom: 0;
    }
  }
}
