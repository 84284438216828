














































































.drawer {
  position: fixed;
  left: 0;
  z-index: 101;

  .header {
    box-shadow: 0 1px 3px 0 @grey;

    .title {
      padding-left: 56px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
