@import "@/styles/scss/_variables.scss";























































































































































.city-selector {
  .ui.dropdown {
    border: none;
    padding: 0;
    border-radius: 24px;
    z-index: 10;

    @media (max-width: $mobile) {
      padding: 10px 10px 10px 15px;
      min-height: 30px;
    }

    .default.text {
      color: $cl-mako-lighten-32;
    }

    &.selection {
      padding: 0;
      border-radius: 24px;

      .menu {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 0;
      }

      .text {
        top: -35px;
        left: 55px;
        font-size: 16px;
        color: $cl-mako-lighten-32;
      }
    }

    &.active {
      .aj-input__textField {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.worktownStep {
  .ui.dropdown.selection>.text {
    left: 14px;
    top: -32px;
    font-size: 14px;
    color: $cl-mako-lighten-32;
  }
}

.moreJobsView__searchLocation {
  .city-selector .ui.dropdown.selection .text {
    left: 20px;
    color: $cl-mako;
  }
}

#city-selector {
  width: 100%;

  :focus {
    border: 1px solid transparent;
  }
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);

  @media (max-width: $mobile) {
    left: 16px;
    width: 16px;
    height: 16px;
  }

  svg {
    color: $cl-radical-red;
  }
}

.app {
  &.-redesignedPage {
    .ui.menu {
      &.-scrolled {
        .dropdown.item {
          color: $cl-white;
        }
      }
      .dropdown.item {
        color: $cl-mako;
      }
    }
  }
}
