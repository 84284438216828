@import "@/styles/scss/_variables.scss";


































.jobOffer {
  &__products {
    margin-top: 20px;
    @media (min-width: $desktop) {
      margin-top: 30px;
    }
  }
  &__productsDisclaimer {
    margin-top: 30px;
    color: $cl-mako-lighten-32;
  }
}
