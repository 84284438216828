@import "@/styles/scss/_variables.scss";





















































































.socialButtons {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;

  .socialButtons__icon {
    display: inline-block;
    height: .85em;
    margin-right: .5em;
    opacity: .8;
  }

  &__button {
    font-weight: 400;

    &.-disabled {
      opacity: .5;
    }
  }

  .-facebook {
    background-color: #3b5998;
    &:hover {
      background-color: #304d8a;
    }
    &:active,
    &:focus {
      background-color: #2d4373;
    }
  }

  .-google {
    background-color: #dd4b39;
    &:hover {
      background-color: #e0321c;
    }
    &:active,
    &:focus {
      background-color: #c23321;
    }
  }
}
