@import "@/styles/scss/_variables.scss";








































.review {
  background: $cl-sail-lighten-12;
  padding: 64px 40px 24px 24px;
  border-radius: 16px;
  width: 310px;
  position: relative;
  overflow: hidden;
  display: flex;

  @media (max-width: $desktop) {
    width: 280px;
  }

  @media (max-width: $mobile) {
    width: 244px;
  }

  @media (max-width: $desktop-small) {
    margin-right: 18px;
  }

  &__shape {
    fill: #c8e6f8;
    position: absolute;
    width: 313px;
    height: 299px;
    left: -61px;
    top: -98px;
    transform: rotate(-34deg);
  }

  &__quote {
    position: absolute;
    width: 34px;
    height: 32px;
    left: 24px;
    top: 16px;
  }

  &__description {
    padding-bottom: 81px;
    display: inline-block;
    position: relative;
  }

  &__bottom {
    position: absolute;
    bottom: 24px;
  }

  &__name {
    margin-bottom: 3px;
    position: relative;
  }

  &__city {
    position: relative;
  }
}
