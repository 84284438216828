@import "@/styles/scss/_variables.scss";








































































































































































.addReview {
  &__form {
    textarea {
      padding: .8em 1em;
      resize: vertical;
      vertical-align: top;
      outline: none;
      font-size: 1em;
      border: 1px solid $cl-mako-alpha-15;
      border-radius: .3rem;
      width: 100%;

      &::placeholder {
        color: $cl-mako-alpha-15;
      }
    }

    label {
      display: block;
    }
  }

  &__stepHeadline {
    margin-bottom: 5px;
  }

  &__stepDescription {
    margin-bottom: 10px;
  }

  &__rates {
    margin: 5px 0 20px;
    .aj-star {
      margin-right: 8px;
    }
  }

  &__summary {
    margin-top: 20px;
  }

  &__navigation {
    margin-bottom: 10px;
  }

  &__dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 10px;
    border-radius: 4px;
    background-color: $cl-dawn-pink-lighten-2;
    transition: all .25s ease-in;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.-active {
      background: $cl-supernova;
    }
  }

  &__selectCity {
    margin-bottom: 20px;
  }

  &__profileLink {
    margin-top: 15px;

    &.aj-button.-link {
      font-size: 16px;
    }
  }

  &__errorMessage {
    margin-bottom: 15px;
  }

  &__ugc {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $cl-mako-alpha-30;

    p {
      margin-bottom: 10px;
    }
  }

  &__ugcButton {
    margin-right: 15px;
  }
}

