


















.rating-label {
  display: block;
  font-size: 1.14rem;
  font-weight: lighter;
  padding: 0 2px;
  margin-top: 10px;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
