.ui.logo {
  transition: height 500ms, width 500ms;
  height: 36px;
  width: 113px;

  @media only screen and (min-width: @tabletBreakpoint) {
    height: 50px;
    width: 155px;
  }

  &.small {
    height: 30px;
    width: 94px;
  }
}
