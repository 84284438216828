@import "@/styles/scss/_variables.scss";







































































.jobOffersList__preview {
  display: none;
  @media (min-width: $tablet) {
    display: block;
    top: 78px;
    position: sticky;
    height: 100vh;
    z-index: 1;
  }

  .jobOffer__main {
    margin-top: 2px;
  }
}
.jobOffersList__previewCard {
  position: relative;
  min-height: 500px;
}
